import { FC } from 'react'

import { AcademicCapIcon } from '@heroicons/react/24/outline'

import TrackItemCard from 'src/components/Library/TrackItemCard/TrackItemCard'
import MilestoneArrow from 'src/components/MilestoneArrow/MilestoneArrow'

import { ErrorTypes } from '../../constants'
import { formatDuration } from '../../helpers'
import { GoalMember } from '../../interfaces'
import { GoalTemplate } from '../TemplatesCell'
interface TemplatePreviewMilestoneProps {
  milestones?: GoalTemplate[] | []
  selectedTemplateId?: number
  setSelectedTemplateId?: (id: number) => void
}
const TemplatePreviewMilestoneItems: FC<TemplatePreviewMilestoneProps> = ({
  milestones,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  const trackItemMilestones = milestones.map((milestone, index) => {
    // Check if the template is the current selected template
    const milestoneIsSelected =
      setSelectedTemplateId && milestone?.id === selectedTemplateId

    // Check if the action is provided and push to onclick
    // a null return prevents any hover effects/actions
    const milestoneOnClick = setSelectedTemplateId
      ? () => {
          setSelectedTemplateId(milestone?.id)
        }
      : null

    // Format the milestone duration
    const milestoneDuration = formatDuration(
      milestone?.dueDateNumber,
      milestone?.dueDateFormat,
    )

    // Set the possible Warning message
    const warning = milestone?.brokenTemplateLoop && (
      <p className="p-2 text-sm">{ErrorTypes.loop}</p>
    )

    // Confirm if Milestone type is a learner item
    const milestoneIsLearnerItem = milestone?.templateType === 'learner'

    // Set the Milestone type label
    const milestoneTypeLabel = milestoneIsLearnerItem ? 'Learner' : 'Milestone'

    // Set the Milestone type icon
    const milestoneIcon = milestoneIsLearnerItem ? (
      <AcademicCapIcon className="h-5 w-5 text-indigo-600" />
    ) : (
      <MilestoneArrow colorClass="text-indigo-600" />
    )

    return (
      <TrackItemCard
        variant="milestone"
        key={milestone?.id}
        isSelected={milestoneIsSelected}
        title={milestoneTypeLabel}
        subheader={milestone?.goalTitle}
        headerIcon={milestoneIcon}
        duration={milestoneDuration}
        onClick={milestoneOnClick}
        className={`${milestoneIsSelected ? 'ring ring-indigo-600' : ''} ${
          index + 1 !== milestones.length ? 'mb-4' : 'mb-100'
        }`}
        warning={warning}
      />
    )
  })

  return <>{trackItemMilestones}</>
}

export default TemplatePreviewMilestoneItems
