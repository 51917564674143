import { Dispatch, FC, SetStateAction } from 'react'

import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'
import { useMediaQuery } from 'usehooks-ts'

import FilterNavTabs from 'src/components/FilterNavTabs/FilterNavTabs'
import Button from 'src/components/Library/Button/Button'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import { EditFormStates } from '../constants'
import TitleHeader from '../Header/TitleHeader'
import { NavTabItem, ViewAsOption } from '../interfaces'

import GoalListAdmin from './GoalList/GoalListAdmin'

interface MainPanelProps {
  navTabItems: NavTabItem[]
  setActiveStatus: Dispatch<SetStateAction<string>>
  selectedTabIndex: number
  searchVal: string
  setSearchVal: Dispatch<SetStateAction<string>>
  showTemplateEditor: boolean
  setShowTemplateEditor: Dispatch<SetStateAction<boolean>>
  userHasAdminAccess: boolean
  adminEditMode: boolean
  setAdminEditMode: Dispatch<SetStateAction<boolean>>
  setSelectedObjective: Dispatch<SetStateAction<number | null>>
  viewAsDropdownOptions: ViewAsOption[]
  viewAsOption: ViewAsOption
  setViewAsOption: Dispatch<SetStateAction<ViewAsOption>>
  sidebarState: EditFormStates.empty | EditFormStates.edit | EditFormStates.new
  setSidebarState: Dispatch<
    SetStateAction<
      EditFormStates.empty | EditFormStates.edit | EditFormStates.new
    >
  >
  resetSidebarState?: () => void
  createGoalFromTemplate?: () => void
}
const MainPanel: FC<MainPanelProps> = ({
  navTabItems,
  setActiveStatus,
  selectedTabIndex,
  searchVal,
  setSearchVal,
  viewAsDropdownOptions,
  viewAsOption,
  setViewAsOption,
  showTemplateEditor,
  setShowTemplateEditor,
  adminEditMode,
  setAdminEditMode,
  userHasAdminAccess,
  setSelectedObjective,
  setSidebarState,
  sidebarState,
  resetSidebarState,
  createGoalFromTemplate,
}) => {
  const { trackEvent } = useAnalytics()

  // Toggle Function for admin mode
  const toggleAdminMode = () => {
    setSearchVal('')
    setViewAsOption(null)
    setSelectedObjective(null)
    setAdminEditMode(!adminEditMode)
    trackEvent('Goals', 'Admin Mode Toggle', {
      toggleVal: !adminEditMode ? 'On' : 'Off',
    })
  }

  const isMobile = useMediaQuery('(max-width: 640px)')

  return (
    <>
      <TitleHeader
        userHasAdminAccess={userHasAdminAccess}
        showTemplateEditor={showTemplateEditor}
        toggleAdminMode={toggleAdminMode}
        adminEditMode={adminEditMode}
        isMobile={isMobile}
      />
      <div className="bg-white border-b border-gray-200 z-20">
        <div className="bg-white hidden xl:flex items-center flex-wrap px-4">
          {!showTemplateEditor && (
            <FilterNavTabs
              navTabs={navTabItems}
              onTabClickCallBack={setActiveStatus}
              selectedTab={selectedTabIndex}
              variant={'standard'}
              scrollButtons={'auto'}
              allowScrollableButtonsMobile
              GATracker={{
                category: 'Goals',
                eventName: 'change tab main goals page',
              }}
            />
          )}
          {showTemplateEditor && (
            <Button
              onClick={() => {
                history.pushState(null, '', '/app/goals/overview')
                setShowTemplateEditor(false)
              }}
              fullWidth={false}
              className="items-center flex px-4 m-1 mb-2"
              variant="text"
              color="secondary"
              startIcon={<ArrowLeftCircleIcon className="w-4 h-4" />}
            >
              <span data-testid="back-to-goals">Back to goals</span>
            </Button>
          )}

          <div className="flex items-center grow justify-end md:min-w-[420px]">
            {!showTemplateEditor && (
              <GoalListAdmin
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                viewAsDropdownOptions={viewAsDropdownOptions}
                viewAsOption={viewAsOption}
                setViewAsOption={setViewAsOption}
                adminEditMode={adminEditMode}
                setSidebarState={setSidebarState}
                sidebarState={sidebarState}
                resetSidebarState={resetSidebarState}
                createGoalFromTemplate={createGoalFromTemplate}
                showTemplateEditor={showTemplateEditor}
                setShowTemplateEditor={setShowTemplateEditor}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MainPanel
