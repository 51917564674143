import { FC } from 'react'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'

import Switch from '../../Library/Switch/Switch'

interface AdminToggleProps {
  toggleAction: () => void
  adminEditModeEnabled: boolean
}

const AdminToggle: FC<AdminToggleProps> = ({
  toggleAction,
  adminEditModeEnabled,
}) => {
  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          componentsProps={{
            typography: {
              className: `text-xs pt-1 ${
                adminEditModeEnabled ? 'text-indigo-600' : 'text-gray-600'
              }`,
            },
          }}
          control={
            <Switch
              checked={adminEditModeEnabled}
              onChange={toggleAction}
              name="admin"
            />
          }
          label={<div className="pb-1">Admin Mode</div>}
        />
      </FormGroup>
    </FormControl>
  )
}
export default AdminToggle
