import { FC } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'

import Button from 'src/components/Library/Button/Button'

import { GoalMember } from '../interfaces'

interface GoalModalHeaderProps {
  selectedTemplate?: GoalMember
  closeModal: () => void
  modalIsSaving?: boolean
  modalStep?: 'select' | 'edit' | 'complete'
}

export const GoalModalHeader: FC<GoalModalHeaderProps> = ({
  selectedTemplate,
  closeModal,
  modalIsSaving,
  modalStep,
}) => {
  return (
    <div className="flex items-center justify-between px-6 py-4 pt-3">
      <h3 className="text-lg leading-6 mb-0 font-medium text-gray-900">
        {modalStep !== 'complete' && (
          <span>
            Create from Template
            {selectedTemplate && (
              <span>
                :&nbsp;
                <span className="text-indigo-600">
                  {selectedTemplate?.goalTitle}
                </span>
              </span>
            )}
          </span>
        )}
      </h3>
      <Button
        onClick={() => closeModal()}
        disabled={modalIsSaving}
        variant="text"
        fullWidth={false}
        className="min-w-[0px]"
      >
        <span className="sr-only">Close</span>
        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
      </Button>
    </div>
  )
}
