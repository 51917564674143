import { Dispatch, FC, SetStateAction } from 'react'

import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { CircularProgress } from '@mui/material'

import Button from 'src/components/Library/Button/Button'

import { GoalMember } from '../interfaces'

interface GoalModalFooterProps {
  modalStep?: 'select' | 'edit' | 'complete'
  setModalStep?: Dispatch<SetStateAction<'select' | 'edit' | 'complete'>>
  selectedTemplate?: GoalMember
  closeModal: () => void
  modalIsSaving?: boolean
  handleNextAction?: () => void
}

export const GoalModalFooter: FC<GoalModalFooterProps> = ({
  modalStep,
  setModalStep,
  closeModal,
  modalIsSaving,
  selectedTemplate,
  handleNextAction,
}) => {
  const actionButtonLabel =
    modalStep === 'edit' ? 'Create Goal' : 'Use Template'

  return (
    <div className="bg-gray-100 px-6 py-4 flex items-center gap-4 justify-between">
      <div>
        {modalStep === 'edit' && (
          <Button
            startIcon={<ChevronLeftIcon className="h-4 w-4" />}
            disabled={modalIsSaving}
            variant="text"
            fullWidth={false}
            onClick={() => {
              setModalStep('select')
            }}
            buttonDataTestId="goal-footer-change-template-button"
          >
            Change Template
          </Button>
        )}
      </div>
      <div className="flex gap-4">
        <Button
          onClick={() => closeModal()}
          disabled={modalIsSaving}
          size="small"
          variant="text"
          fullWidth={false}
          className="px-4"
          buttonDataTestId="goal-footer-cancel-button"
        >
          {modalStep !== 'complete' ? 'Cancel' : 'Close'}
        </Button>
        {modalStep !== 'complete' && (
          <Button
            disabled={
              modalIsSaving || (modalStep === 'select' && !selectedTemplate)
            }
            className="px-4"
            id="improverGoalCreateFromTemplate"
            data-action={'create'}
            size="small"
            onClick={handleNextAction}
            fullWidth={false}
          >
            {modalIsSaving ? (
              <>
                <CircularProgress
                  size={20}
                  thickness={3}
                  id={'sectionSpinner'}
                  sx={{ color: '#B7B7B7' }}
                  className="mr-2"
                />

                {actionButtonLabel}
              </>
            ) : (
              <span data-testid="goal-footer-use-template-button">
                {actionButtonLabel}
              </span>
            )}
          </Button>
        )}
      </div>
    </div>
  )
}
