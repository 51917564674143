import { useState, useEffect, FC, Dispatch, SetStateAction } from 'react'

import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { SortingOptions } from 'src/components/Goals/constants'
import useAnalytics from 'src/lib/hooks/useAnalytics'
interface HeaderFilterProps {
  visibleGoalCount: number
  totalGoalCount: number
  setGoalsSortValue: Dispatch<SetStateAction<string>>
  resetSidebarState?: () => void
  formFieldsChanged: boolean
  setSaveGoal: Dispatch<SetStateAction<boolean>>
}
const HeaderFilter: FC<HeaderFilterProps> = ({
  visibleGoalCount,
  totalGoalCount,
  setGoalsSortValue,
  resetSidebarState,
  formFieldsChanged,
  setSaveGoal,
}) => {
  const { trackEvent } = useAnalytics()

  // Sorting options
  const sortOptions = [
    SortingOptions.DueDate,
    SortingOptions.DateCreated,
    SortingOptions.Status,
    SortingOptions.Progress,
  ]

  // Sorting option selected state
  const [sortSelection, setSortSelection] = useState<string>(
    SortingOptions.DueDate,
  )

  // Sorting option handle change
  const handleSortChange = (event: SelectChangeEvent) => {
    setSortSelection(event.target.value)
    trackEvent('Goals', 'change sort', {
      sortSelection: event.target.value,
    })
  }

  // On new select, update sort value
  useEffect(() => {
    if (setGoalsSortValue) {
      setGoalsSortValue(sortSelection)
    }
  }, [sortSelection])

  return (
    <div
      className="flex justify-between items-center pt-4 pb-2 text-sm px-6"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          if (formFieldsChanged) {
            // form changes detected, confirm before resetting the state
            setSaveGoal(true)
          } else {
            // no form changes found, reset the state
            resetSidebarState()
          }
        }
      }}
      onKeyUp={() => {}}
      tabIndex={0}
      role="button"
    >
      <span className="text-gray-600" data-testid="visible-goals-span">
        Showing {visibleGoalCount} of {totalGoalCount} Goals.
      </span>
      <FormControl
        variant="standard"
        className="border-0 m-0 bg-gray-50"
        size="small"
      >
        <div className="flex items-center gap-3">
          <span className="text-xs text-gray-600 pt-0.5">Sort By</span>
          <Select
            className="pt-2 bg-transparent focus:bg-transparent fix this background issue color-indigo-600"
            value={sortSelection}
            onChange={handleSortChange}
            disableUnderline
            sx={{
              '& .MuiSelect-select': {
                color: 'rgb(79, 70, 229)',
                width: '90px',
                fontSize: '14px',
              },
              '& .MuiSelect-select:focus': {
                background: 'transparent!important',
              },
            }}
          >
            {sortOptions.map((sortValue, index) => (
              <MenuItem className="pt-2" key={index} value={sortValue}>
                {sortValue}
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
    </div>
  )
}

export default HeaderFilter
