import { Dispatch, FC, SetStateAction } from 'react'

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import useMediaQuery from '@mui/material/useMediaQuery'

import { EditFormStates } from 'src/components/Goals/constants'
import { GoalListCellItemType } from 'src/components/Goals/interfaces'
import Button from 'src/components/Library/Button/Button'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import GoalObjective from './GoalObjective/GoalObjective'
import Milestone from './Milestone/Milestone'
interface GoalItemProps {
  improverGoals: GoalListCellItemType[]
  selectedObjective: number | null
  setSelectedObjective: Dispatch<SetStateAction<number | null>>
  setSidebarState: Dispatch<
    SetStateAction<
      EditFormStates.empty | EditFormStates.edit | EditFormStates.new
    >
  >
  userHasEditAccess: boolean
  creatingMilestone: boolean
  setCreatingMilestone?: Dispatch<SetStateAction<boolean>>
  adminEditMode: boolean
  foundGoalId?: number | null
}
const GoalItem: FC<GoalItemProps> = ({
  improverGoals,
  selectedObjective,
  setSelectedObjective,
  setSidebarState,
  userHasEditAccess,
  creatingMilestone,
  setCreatingMilestone,
  adminEditMode,
  foundGoalId,
}) => {
  // Current user
  const { currentUser } = useAuth()

  const { trackEvent } = useAnalytics()
  const isMobile = useMediaQuery('(max-width: 640px)')
  const milestoneOnClick = (milestoneId: number) => {
    setCreatingMilestone(false)
    setSelectedObjective(milestoneId)
    setSidebarState(EditFormStates.edit)

    trackEvent('Goals', 'Click on Milestone', {
      milestoneId: milestoneId,
    })
  }

  return (
    <div
      className="mx-3 sm:mx-6 mb-14 pb-5 full-objectives-list flex flex-col"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setSidebarState(EditFormStates.empty)
          setSelectedObjective(null)
          setCreatingMilestone(false)
        }
      }}
      onKeyUp={() => {}}
      tabIndex={0}
      role="button"
    >
      {improverGoals.map((goal) => (
        <GoalObjective
          goal={goal}
          foundGoalId={foundGoalId}
          key={goal.id}
          setCreatingMilestone={setCreatingMilestone}
          selectedObjective={selectedObjective}
          setSelectedObjective={setSelectedObjective}
          setSidebarState={setSidebarState}
          allowEdit={userHasEditAccess}
          allowOpen={
            userHasEditAccess ||
            Boolean(goal?.goalBody || goal?.childGoals.length > 0)
          }
        >
          <>
            <div className="flex flex-col sm:pl-8 sm:border-l border-gray-300">
              {goal?.childGoals.map((milestone) => (
                <Milestone
                  key={milestone.id}
                  milestone={milestone}
                  onClick={milestoneOnClick}
                  isSelected={selectedObjective === milestone.id}
                />
              ))}
            </div>
            {!isMobile &&
              userHasEditAccess &&
              (adminEditMode ||
                goal?.createdBy === currentUser.userData.id) && (
                <Button
                  className="my-2 hover:bg-indigo-50 ml-9 px-4 group"
                  disabled={creatingMilestone}
                  fullWidth={false}
                  variant="text"
                  onClick={() => {
                    // enable milestone creating
                    setCreatingMilestone(true)
                    //set the parent id
                    setSelectedObjective(goal.id)
                    // set to create 'new'
                    setSidebarState(EditFormStates.new)
                  }}
                  startIcon={<PlusCircleIcon className="w-6 h-6" />}
                  buttonDataTestId={`add-milestone-${goal.goalTitle}`}
                >
                  Add a new Milestone
                </Button>
              )}
          </>
        </GoalObjective>
      ))}
    </div>
  )
}

export default GoalItem
