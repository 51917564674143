import { FC, useEffect, useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { useLocation } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import GoalListCell from 'src/components/Goals/MainPanel/GoalList/GoalListCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import useLocalStorage from 'src/lib/hooks/UseLocalStorage'
import { useAuth } from 'web/src/Providers'

interface Props {
  templateId?: string
  goalId?: string
}

const ImproverGoalsPage: FC<Props> = ({ templateId, goalId }) => {
  // Current URL Path
  const { pathname } = useLocation()

  // Get user access level
  const { hasRole, currentUser } = useAuth()
  const userHasAdminAccess = hasRole(['ADMIN', 'OWNER'])

  // Current User's MemberID
  let currentUserMembershipId = currentUser?.membershipData?.id

  // Get Current clientID
  const clientId = currentUser?.parentData?.id

  // Admin toggle label for LocalStorage
  const adminEditModeLabel = `memberId-${currentUserMembershipId}-goalsAdminToggle`

  // if a user was admin but is no longer admin, revoke their admin mode
  if (!userHasAdminAccess) {
    localStorage.setItem(adminEditModeLabel, 'false')
  }

  // Set LocalStorage
  const [goalsAdminLocalStorage, setGoalsAdminLocalStorage] = useLocalStorage(
    adminEditModeLabel,
    userHasAdminAccess,
  )

  // Admin is in edit mode
  const [adminEditMode, setAdminEditMode] = useState<boolean>(
    goalsAdminLocalStorage,
  )

  // If the admin is in admin mode, show all users items
  if (adminEditMode) {
    currentUserMembershipId = undefined
  }

  // On change, update the LocalStorage value
  useEffect(() => {
    setGoalsAdminLocalStorage(adminEditMode)
  }, [adminEditMode])

  const goalIdNumber = goalId ? parseInt(goalId) : undefined
  const templateIdNumber = templateId ? parseInt(templateId) : undefined

  return (
    <>
      <Metadata title="Goals" description="Goals" />
      <FeatureToggle
        feature={FLAG_FEATURES.GOALS}
        InactiveComponent={<FeatureDenied />}
      >
        <GoalListCell
          clientId={clientId}
          membershipId={currentUserMembershipId}
          userHasAdminAccess={userHasAdminAccess}
          adminEditMode={adminEditMode}
          setAdminEditMode={setAdminEditMode}
          pathname={pathname}
          templateId={templateIdNumber}
          goalId={goalIdNumber}
        />
      </FeatureToggle>
    </>
  )
}

export default ImproverGoalsPage
