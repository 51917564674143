import { FC } from 'react'

import {
  ImproverGoalMemberTemplatesQuery,
  ImproverGoalMemberTemplatesQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import Templates from '../Templates'

export const QUERY = gql`
  query ImproverGoalMemberTemplatesQuery(
    $isTemplate: Boolean!
    $isGlobal: Boolean!
  ) {
    goalTemplates: improverGoalMemberTemplatesWithOrderedMilestones(
      isTemplate: $isTemplate
      isGlobal: $isGlobal
    ) {
      ...ImproverGoalMemberTemplateFragment
      orderedMilestones {
        dayNumber
        items {
          ...ImproverGoalMemberTemplateFragment
          brokenTemplateLoop
        }
      }
      childGoals {
        ...ImproverGoalMemberTemplateFragment
      }
      client {
        name
      }
    }
    learnerCourses: getLearnerCoursesTaskTypeCount {
      id
      name
      learnerCategory {
        id
        name
        isGlobal
      }
      heroStorageObject {
        downloadUrl
      }
      courseTypeCounts {
        text
        video
        quiz
      }
    }
  }

  fragment ImproverGoalMemberTemplateFragment on ImproverGoalMember {
    id
    clientId
    goalTitle
    goalBody
    isComplete
    createdAt
    dueDate
    startDate
    startValue
    currentValue
    targetValue
    status
    parentGoalId
    isTemplate
    isGlobal
    templateUserRoles
    templateType
    startDateNumber
    startDateFormat
    startDateTrigger
    startDateTriggerMilestoneId
    dueDateNumber
    dueDateFormat
    dueDateTrigger
    dueDateTriggerMilestoneId
    attachedLearnerItemId
  }
`

export const Loading = () => <LoadingSpinner />
type Props = CellSuccessProps<
  ImproverGoalMemberTemplatesQuery,
  ImproverGoalMemberTemplatesQueryVariables
> & {
  pathname: string
  templateId: number
}
export type GoalTemplate = Props['goalTemplates'][0]
export const Empty: FC<Props> = ({
  goalTemplates,
  pathname,
  templateId,
  learnerCourses,
  queryResult: { refetch },
}) => {
  // Filter and return only parent level items
  const parentTemplates = goalTemplates.filter(
    (template) => template.parentGoalId === null,
  )

  const learnerCourseArr = [...learnerCourses]
  const sortedLearnerCourses = learnerCourseArr.sort((a, b) => {
    return a?.learnerCategory?.name > b?.learnerCategory?.name ? 1 : -1
  })

  return (
    <Templates
      templates={goalTemplates}
      parentTemplates={parentTemplates}
      courseOptions={sortedLearnerCourses}
      pathname={pathname}
      templateId={templateId}
      refetch={refetch}
    />
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<Props> = ({
  goalTemplates,
  pathname,
  templateId,
  learnerCourses,
  queryResult: { refetch },
}) => {
  // Filter and return only parent level items
  const parentTemplates = goalTemplates.filter(
    (template) => template.parentGoalId === null,
  )

  const learnerCourseArr = [...learnerCourses]
  const sortedLearnerCourses = learnerCourseArr.sort((a, b) => {
    return a?.learnerCategory?.name > b?.learnerCategory?.name ? 1 : -1
  })

  return (
    <Templates
      templates={goalTemplates}
      parentTemplates={parentTemplates}
      courseOptions={sortedLearnerCourses}
      pathname={pathname}
      templateId={templateId}
      refetch={refetch}
    />
  )
}
