import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { Grid2 as Grid } from '@mui/material'
import { GoalStatusTypes, NewGoalStatusTypes } from 'api/src/common/enums'
import { useMediaQuery } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'

import GoalItem from 'src/components/Goals/MainPanel/GoalList/GoalItem/GoalItem'
import SidePanel from 'src/components/Goals/SidePanel/SidePanel'
import TemplatesCell from 'src/components/Goals/Templates/TemplatesCell'
import Empty from 'src/components/Library/Empty/Empty'
import Loading from 'src/components/Library/Loading/Loading'
import { useAuth } from 'src/Providers'

import { EditFormStates, SortingOptions, ViewAsGroups } from '../../constants'
import GoalTargetIcon from '../../GoalTargetIcon/GoalTargetIcon'
import {
  countGoals,
  filterGoalsByInput,
  setGoalOptions,
  sortViewOptionsByName,
} from '../../helpers'
import {
  GoalListCellItemType,
  MembershipGroup,
  ViewAsOption,
} from '../../interfaces'
import { GoalModal } from '../../Modal/GoalModal'
import MainPanel from '../MainPanel'

import {
  GoalListCellMembership,
  GoalListCellMembershipGroup,
} from './GoalListCell'
import HeaderFilter from './Header/HeaderFilter'

interface GoalListProps {
  clientId: number
  membershipId: number
  goals?: GoalListCellItemType[]
  memberships?: GoalListCellMembership[]
  membershipGroups?: MembershipGroup[] | GoalListCellMembershipGroup[]
  userHasAdminAccess: boolean
  adminEditMode: boolean
  setAdminEditMode: Dispatch<SetStateAction<boolean>>
  refetch: () => Promise<unknown>
  pathname: string
  templateId: number | undefined | null
  goalId?: number
  isUpdating?: boolean
}

const GoalList: FC<GoalListProps> = ({
  goals,
  memberships,
  membershipGroups,
  userHasAdminAccess,
  adminEditMode,
  setAdminEditMode,
  refetch,
  pathname,
  templateId,
  goalId,
  isUpdating,
}) => {
  const { currentUser } = useAuth()
  const isMobile = useMediaQuery('(max-width: 640px)')
  const currentUserMembershipGroupOptions: ViewAsOption[] =
    currentUser?.membershipData?.membershipGroups.map((group) => {
      return {
        id: group?.id,
        name: group?.name,
        group: ViewAsGroups.membershipGroup,
      }
    })

  // Count Goals Statuses
  const goalStatusCount = countGoals(goals)

  // Allow users to edit goals
  const userCanEditGoals = true

  // Set Nav Tab Filter counts
  const navTabItems: {
    key?: GoalStatusTypes
    title: NewGoalStatusTypes | 'All'
    count: number
  }[] = [
    {
      key: GoalStatusTypes.inProgress,
      title: NewGoalStatusTypes.inProgress,
      count: goalStatusCount.inProgress,
    },
    {
      key: GoalStatusTypes.blocked,
      title: NewGoalStatusTypes.blocked,
      count: goalStatusCount.blocked,
    },
    {
      key: GoalStatusTypes.overdue,
      title: NewGoalStatusTypes.overdue,
      count: goalStatusCount.overdue,
    },
    {
      key: GoalStatusTypes.completed,
      title: NewGoalStatusTypes.completed,
      count: goalStatusCount.completed,
    },

    { title: 'All', count: goalStatusCount.all },
  ]

  // Membership Group Selector Options
  // If admin mode, pass all - else pass personal groups
  const membershipGroupOptions: ViewAsOption[] = !adminEditMode
    ? currentUserMembershipGroupOptions
    : membershipGroups.map((group) => {
        return {
          id: group.id,
          name: group.name,
          group: ViewAsGroups.membershipGroup,
        }
      })

  // Membership Selector Options
  // If admin mode, pass all - else pass empty
  const membershipOptions: ViewAsOption[] = !adminEditMode
    ? []
    : memberships.map((member) => {
        return {
          id: member?.id,
          name: member?.user?.name,
          group: ViewAsGroups.membership,
          avatar: member?.user?.avatarUrl,
        }
      })

  // Combine them for the selector
  const viewAsDropdownOptions: ViewAsOption[] = [
    ...sortViewOptionsByName(membershipGroupOptions),
    ...sortViewOptionsByName(membershipOptions),
  ]

  // Sidebar State Options
  const [sidebarState, setSidebarState] = useState<
    EditFormStates.empty | EditFormStates.edit | EditFormStates.new
  >(EditFormStates.empty)

  const [selectedObjective, setSelectedObjective] = useState<number | null>(
    null,
  )
  const [formFieldsChanged, setFormFieldsChanged] = useState(false)
  const [activeStatus, setActiveStatus] = useState<GoalStatusTypes | 'All'>(
    GoalStatusTypes.inProgress,
  )
  const [showTemplateEditor, setShowTemplateEditor] = useState<boolean>(false)

  // Filter State Options
  const [searchVal, setSearchVal] = useState<string>('')
  const [viewAsOption, setViewAsOption] = useState<ViewAsOption>(null)
  const [goalsSortValue, setGoalsSortValue] = useState<SortingOptions>()
  const [creatingMilestone, setCreatingMilestone] = useState<boolean>(false)

  // GOALS State Options
  const [improverGoals, setImproverGoals] = useState(
    filterGoalsByInput(viewAsOption, searchVal, goals, 'All', goalsSortValue),
  )
  const [saveGoal, setSaveGoal] = useState(false)
  const handleCloseModal = useCallback((createdGoalId?: number) => {
    setIsModalOpen(false)

    if (createdGoalId) {
      navigate(routes.improverGoals({ goalId: createdGoalId }))
    }
  }, [])

  // On Search Filter inputs, filter the goals
  useEffect(() => {
    setImproverGoals(
      filterGoalsByInput(
        viewAsOption,
        searchVal,
        goals,
        activeStatus,
        goalsSortValue,
      ),
    )
  }, [searchVal, goals, activeStatus, goalsSortValue, viewAsOption])

  // Goals formatted for quick selecting
  const allGoals = goals.flatMap((goal) => {
    return [goal, ...goal.childGoals]
  })
  const sendGoals = setGoalOptions(allGoals)

  // Set the selected tab index
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const getSelectedTabIndex = (currentStatus: GoalStatusTypes | 'All') => {
    return navTabItems.findIndex((item) => item.key === currentStatus)
  }

  // Callback function for Filter Navtabs
  const setFilterItem = (value: GoalStatusTypes | 'All') => {
    setActiveStatus(value)
    setShowTemplateEditor(false)
  }

  // Reset the sidebar to empty state
  const resetSidebarState = () => {
    setSidebarState(EditFormStates.empty)
    setSelectedObjective(null)
  }

  // Reset selected objective on mode change
  useEffect(() => {
    resetSidebarState()
  }, [adminEditMode])

  // Search Filters have been applied
  const searchFiltersActive = searchVal.length > 0 || viewAsOption !== null

  let emptyTitle = 'No Goals found.'
  let emptyDescription = 'Create a new goal to get started.'

  if (searchFiltersActive) {
    emptyTitle = 'No Goals match your search.'
    emptyDescription = 'Try another search term or group selection.'
  }

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const createGoalFromTemplate = () => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    // reset the states when switching between template editor and goals view
    setSelectedObjective(null)
    setSidebarState(EditFormStates.empty)
  }, [showTemplateEditor])

  // Update url when goal is selected, this helps with deep linking
  if (selectedObjective) {
    window.history.pushState(
      {},
      '',
      routes.improverGoalsEdit({ goalId: selectedObjective }),
    )
  } else {
    window.history.pushState({}, '', routes.improverGoals())
  }

  // On load, check if the template is set
  useEffect(() => {
    // If pathname === templates or templateId is set (allows 0)
    // Show the template component
    if (
      pathname === '/app/goals/overview/templates' ||
      templateId !== undefined
    ) {
      setShowTemplateEditor(true)
    } else if (goalId !== undefined) {
      // check if goalID is a child goal, if so set the parent goal as selected
      const goal = sendGoals[goalId]
      setSidebarState(EditFormStates.edit)

      if (sendGoals[goalId]) {
        // If the goal is a child goal, select the parent goal
        if (goal.parentGoalId) {
          setSelectedObjective(goal.id)
          // set tab index and active status for the parent goal
          const parentGoal = sendGoals[goal.parentGoalId]
          setActiveStatus(parentGoal.currentStatus as GoalStatusTypes)
          setSelectedTabIndex(
            getSelectedTabIndex(parentGoal.currentStatus as GoalStatusTypes),
          )
        } else {
          // If the goal can be found, select it, otherwise navigate to goals page
          setSidebarState(EditFormStates.edit)
          setSelectedObjective(goalId)
          // set tab index and active status
          setActiveStatus(goal.currentStatus as GoalStatusTypes)
          setSelectedTabIndex(
            getSelectedTabIndex(goal.currentStatus as GoalStatusTypes),
          )
        }
      } else {
        // Just navigate to basic goal page
        navigate(routes.improverGoals())
      }
    }
  }, [goalId])

  return (
    <Grid
      className="grid h-screen w-full grid-cols-1 grid-rows-[max-content_auto] xl:grid-cols-[1fr_450px]"
      container
    >
      <Grid className="col-[span_2] h-fit">
        <MainPanel
          navTabItems={navTabItems}
          setActiveStatus={setFilterItem}
          selectedTabIndex={selectedTabIndex}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          viewAsDropdownOptions={viewAsDropdownOptions}
          viewAsOption={viewAsOption}
          setViewAsOption={setViewAsOption}
          showTemplateEditor={showTemplateEditor}
          setShowTemplateEditor={setShowTemplateEditor}
          adminEditMode={adminEditMode}
          setAdminEditMode={setAdminEditMode}
          setSelectedObjective={setSelectedObjective}
          userHasAdminAccess={userHasAdminAccess}
          setSidebarState={setSidebarState}
          sidebarState={sidebarState}
          resetSidebarState={resetSidebarState}
          createGoalFromTemplate={createGoalFromTemplate}
        />
      </Grid>

      {showTemplateEditor && (
        <Grid className="col-[span_2] overflow-y-auto">
          <TemplatesCell
            isTemplate={true}
            isGlobal={false}
            pathname={pathname}
            templateId={templateId}
          />
        </Grid>
      )}
      {!showTemplateEditor && (
        <>
          <Grid
            className={`${isUpdating ? 'overflow-hidden' : 'overflow-y-auto'} flex ${
              !improverGoals.length ? 'flex-col' : 'flex-row'
            } justify-around`}
            onClick={(event) => {
              if (event.target === event.currentTarget) {
                if (formFieldsChanged) {
                  // form changes detected, confirm before resetting the state
                  setSaveGoal(true)
                } else {
                  // no form changes found, reset the state
                  setSidebarState(EditFormStates.empty)
                  setSelectedObjective(null)
                  setCreatingMilestone(false)
                }
              }
            }}
          >
            {!improverGoals.length &&
              (isUpdating ? (
                <Loading />
              ) : (
                <Empty
                  icon={
                    <GoalTargetIcon
                      sizeInPixels={200}
                      fillColour="#D1D5DB"
                      className="mb-4 pl-3"
                    />
                  }
                  title={emptyTitle}
                  description={emptyDescription}
                ></Empty>
              ))}
            {improverGoals.length > 0 && (
              <Grid
                className="relative h-fit w-full pb-20 xl:pb-0"
                onClick={(event) => {
                  if (event.target === event.currentTarget) {
                    if (formFieldsChanged) {
                      // form changes detected, confirm before resetting the state
                      setSaveGoal(true)
                    } else {
                      // no form changes found, reset the state
                      setSidebarState(EditFormStates.empty)
                      setSelectedObjective(null)
                      setCreatingMilestone(false)
                    }
                  }
                }}
              >
                <HeaderFilter
                  visibleGoalCount={improverGoals.length}
                  totalGoalCount={goals.length}
                  setGoalsSortValue={setGoalsSortValue}
                  resetSidebarState={resetSidebarState}
                  formFieldsChanged={formFieldsChanged}
                  setSaveGoal={setSaveGoal}
                />
                <GoalItem
                  foundGoalId={goalId}
                  improverGoals={improverGoals}
                  selectedObjective={selectedObjective}
                  setSelectedObjective={setSelectedObjective}
                  setSidebarState={setSidebarState}
                  userHasEditAccess={userCanEditGoals}
                  creatingMilestone={creatingMilestone}
                  setCreatingMilestone={setCreatingMilestone}
                  adminEditMode={adminEditMode}
                />
                {isUpdating && (
                  <div className="absolute inset-0 z-50 bg-gray-50 opacity-50">
                    <Loading />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
          {!isMobile && (
            <Grid className={'flex flex-row overflow-y-auto'}>
              <SidePanel
                sidebarState={sidebarState}
                setSidebarState={setSidebarState}
                goals={sendGoals}
                selectedObjective={selectedObjective}
                setSelectedObjective={setSelectedObjective}
                creatingMilestone={creatingMilestone}
                setCreatingMilestone={setCreatingMilestone}
                createGoalFromTemplate={createGoalFromTemplate}
                refetch={refetch}
                adminEditMode={adminEditMode}
                viewAsOption={viewAsOption}
                formFieldsChanged={formFieldsChanged}
                setFormFieldsChanged={setFormFieldsChanged}
                saveGoal={saveGoal}
                setSaveGoal={setSaveGoal}
              />
              <GoalModal
                isModalOpen={isModalOpen}
                closeModal={handleCloseModal}
                adminEditMode={adminEditMode}
                refetch={refetch}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default GoalList
