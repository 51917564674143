import React, { Dispatch, FC, SetStateAction } from 'react'

import { PencilIcon } from '@heroicons/react/24/outline'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'

import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import { GoalMember } from '../../interfaces'

import TemplatesTypeIndicator from './TemplatesTypeIndicator'
interface TemplatesSidebarHeaderProps {
  onClickDelete: (id: number) => void
  onClickEdit: (id: number) => void
  editMode: boolean
  selectedTemplate: GoalMember | null
  isSaving: boolean
  formFieldsChanged: boolean
  setHandleFormSave: Dispatch<SetStateAction<boolean>>
  resetToTemplateList: () => void
}
const TemplatesSidebarHeader: FC<TemplatesSidebarHeaderProps> = ({
  onClickDelete,
  onClickEdit,
  selectedTemplate,
  editMode,
  isSaving,
  formFieldsChanged,
  setHandleFormSave,
  resetToTemplateList,
}) => {
  // Init Confirm
  const confirmDelete = useConfirm()

  // Set Confirm title
  const confirmTitle = (
    <p>
      Delete {selectedTemplate?.parentGoalId ? 'Milestone' : 'Template'}:{' '}
      <span className="text-indigo-600 capitalize">
        {selectedTemplate?.goalTitle}
      </span>
    </p>
  )

  // Set Confirm description
  const confirmDescription = selectedTemplate?.parentGoalId
    ? 'Are you sure you want to delete this milestone?'
    : 'Are you sure you want to delete this template?'

  return (
    <>
      <div className="flex gap-2 items-center justify-between p-4 sticky top-0 bg-white border-b border-gray-200 z-10">
        {editMode ? (
          <div className="flex">
            <Tooltip title="Back to templates" placement="top">
              <div>
                <IconButton
                  className="text-gray-500 hover:!bg-gray-100 rounded-md mr-2"
                  onClick={() => {
                    resetToTemplateList()
                  }}
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </IconButton>
              </div>
            </Tooltip>
            <div className="flex gap-2 text-sm uppercase items-center">
              <TemplatesTypeIndicator
                isMilestone={selectedTemplate?.parentGoalId !== null}
              />
            </div>
          </div>
        ) : (
          <Button
            size="small"
            variant="contained"
            fullWidth={false}
            className="px-3 py-1"
            onClick={() => {
              onClickEdit(selectedTemplate.id)
            }}
            startIcon={<PencilIcon className="w-4 h-4" />}
          >
            Edit Template
          </Button>
        )}
        <div className="flex">
          <Button
            variant="contained"
            className="mr-2"
            onClick={() => setHandleFormSave(true)}
            loading={isSaving}
            disabled={!formFieldsChanged}
            buttonDataTestId={`templates-save-button-enabled-${formFieldsChanged}`}
          >
            Save
          </Button>
          <Button
            disabled={isSaving}
            size="small"
            color="error"
            variant="outlined"
            fullWidth={false}
            onClick={() => {
              confirmDelete({
                title: confirmTitle,
                description: confirmDescription,
              })
                .then(() => {
                  onClickDelete(selectedTemplate.id)
                })
                .catch(() => {
                  // Do Nothing - user cancelled
                  // Empty catch avoids error logged to console
                })
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  )
}
export default TemplatesSidebarHeader
