import React, { ReactNode } from 'react'

import { ClockIcon, SwatchIcon } from '@heroicons/react/24/outline'
import { Divider } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import { calculateTimeSince } from 'src/Util'

interface Props {
  headerIcon?: JSX.Element
  title?: React.ReactNode
  subheader?: React.ReactNode
  author?: string
  className?: string
  isSelected?: boolean
  createdTime?: string | Date
  duration?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  parentDataTestId?: string
  color?: string
  children?: ReactNode
}
const TemplateCard: React.FC<Props> = ({
  headerIcon,
  title = 'Template',
  subheader,
  children,
  author,
  isSelected,
  createdTime,
  duration,
  onClick,
  parentDataTestId,
  color = 'indigo',
}) => {
  if (!headerIcon) {
    headerIcon = <SwatchIcon className={`w-5 h-5 text-${color}-600`} />
  }

  return (
    <Card
      variant="outlined"
      onClick={onClick}
      className={`hover:cursor-pointer hover:ring hover:ring-${color}-600 ${
        isSelected && `ring ring-${color}-600`
      } transition ease-in-out duration-300`}
      data-testid={parentDataTestId}
    >
      <CardHeader
        className="p-4"
        avatar={headerIcon}
        title={
          <span>
            <h1
              className={`text-${color}-600 text-xs leading-4 font-medium tracking-wider uppercase mt-0.5`}
            >
              {title}
            </h1>
          </span>
        }
        subheader={subheader}
      />
      {children && (
        <CardContent className="pt-0">
          <div className="text-sm font-light leading-5 text-gray-700">
            {children}
          </div>
        </CardContent>
      )}
      <CardContent className="p-4 pt-0 !pb-2">
        <Divider />
        <div className="w-full flex items-center justify-between mt-4">
          <p className="text-xs leading-4 font-light tracking-wider uppercase text-gray-400">
            {author}
          </p>
          {createdTime && (
            <div className="flex ">
              <ClockIcon className="w-5 h-5 text-gray-400" />
              <p className="text-xs leading-4 font-light tracking-wider uppercase text-gray-400 ml-2 mt-0.5">
                {calculateTimeSince(createdTime)}
              </p>
            </div>
          )}
          {duration && (
            <div className="flex ">
              <ClockIcon className="w-5 h-5 text-gray-400" />
              <p className="text-xs leading-4 font-light tracking-wider uppercase text-gray-400 ml-2 mt-0.5">
                {duration}
              </p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default TemplateCard
