import { FC } from 'react'

import AdminToggle from 'src/components/Goals/Header/AdminToggle'
import PageHeader from 'src/components/PageHeader/PageHeader'

interface TitleHeaderProps {
  userHasAdminAccess: boolean
  showTemplateEditor: boolean
  toggleAdminMode: () => void
  adminEditMode: boolean
  isMobile: boolean
}

const TitleHeader: FC<TitleHeaderProps> = ({
  userHasAdminAccess,
  showTemplateEditor,
  toggleAdminMode,
  adminEditMode,
  isMobile,
}) => (
  <PageHeader title="Goals">
    {!isMobile && userHasAdminAccess && !showTemplateEditor && (
      <AdminToggle
        toggleAction={toggleAdminMode}
        adminEditModeEnabled={adminEditMode}
      />
    )}
  </PageHeader>
)

export default TitleHeader
