// CREATE GOAL TEMPLATE ITEMS
export const CREATE_GOAL_TEMPLATE_ITEM = gql`
  mutation CreateImproverGoalMemberTemplateMutation(
    $input: CreateImproverGoalMemberInput!
    $adminEditMode: Boolean!
  ) {
    createImproverGoalMember(input: $input, adminEditMode: $adminEditMode) {
      id
    }
  }
`

// CREATE MANY GOAL TEMPLATE ITEMS
export const CREATE_GOAL_TEMPLATE_ITEMS = gql`
  mutation CreateManyImproverGoalMemberTemplateMutation(
    $input: [CreateImproverGoalMemberInput!]!
    $initialPermissions: InitialGoalPermission
  ) {
    createManyImproverGoalMember(
      input: $input
      initialPermissions: $initialPermissions
    ) {
      id
    }
  }
`

// DELETE GOAL TEMPLATE ITEMS
export const DELETE_GOAL_TEMPLATE_ITEM = gql`
  mutation DeleteImproverGoalTemplateMember(
    $id: Int!
    $adminEditMode: Boolean!
  ) {
    deleteImproverGoalMember(id: $id, adminEditMode: $adminEditMode) {
      id
      parentGoalId
    }
  }
`

// UPDATE GOAL TEMPLATE ITEMS
export const UPDATE_GOAL_TEMPLATE_ITEM = gql`
  mutation UpdateImproverGoalTemplateMember(
    $input: UpdateImproverGoalMemberInput!
    $id: Int!
    $adminEditMode: Boolean!
  ) {
    updateImproverGoalMember(
      input: $input
      id: $id
      adminEditMode: $adminEditMode
    ) {
      __typename
      id
      isGlobal
    }
  }
`
