import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  EllipsisHorizontalIcon,
  GlobeAltIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/solid'
import { Chip, CircularProgress, TextField, Tooltip } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { DatePicker } from '@mui/x-date-pickers'
import { GoalStatusTypes, NewGoalStatusTypes } from 'api/src/common/enums'
import dayjs from 'dayjs'
import type {
  CreateImproverGoalMemberObjFormMutation,
  CreateImproverGoalMemberObjFormMutationVariables,
  CreatePersonalImproverGoalMemberObjFormMutation,
  CreatePersonalImproverGoalMemberObjFormMutationVariables,
  DeleteImproverGoalMember,
  DeleteImproverGoalMemberVariables,
  FindImproverGoalMemberGridQuery,
  FindImproverGoalMemberGridQueryVariables,
  findLearnerCoursesQuery,
  findLearnerCoursesQueryVariables,
  TableName,
  UpdateImproverGoalMemberObjFormMutation,
  UpdateImproverGoalMemberObjFormMutationVariables,
  ImproverGoalMemberFields,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { updateCache } from 'src/apolloCache/functions/updateCache'
import DebounceNumberInput from 'src/components/DebounceNumberInput'
import DebounceTextInput from 'src/components/DebounceTextInput'
import {
  FormInputRow,
  FormSpacer,
} from 'src/components/Goals/Templates/TemplateEditForm'
import CourseProgressPreview from 'src/components/Learner/Courses/CoursePreview/CourseProgressPreview'
import Accordion from 'src/components/Library/Accordion/Accordion'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import Loading from 'src/components/Library/Loading/Loading'
import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'
import Switch from 'src/components/Library/Switch/Switch'
import ToastMessage from 'src/components/ToastMessage/ToastMessage'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'
import { apolloCache } from 'web/src/apolloCache/apolloCache'
import { useAuth } from 'web/src/Providers'

import {
  EditFormStates,
  ProgressionTypes,
  StatusBGColorsDark,
} from '../../constants'
import GoalTargetIcon from '../../GoalTargetIcon/GoalTargetIcon'
import {
  Assignee,
  GoalListCellItemType,
  GoalOption,
  GoalsExtended,
  ParentGoal,
  ViewAsOption,
} from '../../interfaces'
import { QUERY as FIND_IMPROVER_GOAL_MEMBER_GRID_QUERY } from '../../MainPanel/GoalList/GoalListCell'
import {
  CREATE_GOAL_ITEM,
  CREATE_PERSONAL_GOAL_ITEM,
  DELETE_GOAL_ITEM,
  UPDATE_GOAL_ITEM,
  FIND_COURSE_BY_ID_QUERY,
  FIND_LEARNER_COURSES_QUERY,
} from '../../Queries/queries'

interface EditObjectiveFormProps {
  sidebarState: EditFormStates.empty | EditFormStates.edit | EditFormStates.new
  setSidebarState: Dispatch<
    SetStateAction<
      EditFormStates.empty | EditFormStates.edit | EditFormStates.new
    >
  >
  selectedObjective: number | null
  setSelectedObjective: Dispatch<SetStateAction<number | null>>
  goals: GoalOption<GoalListCellItemType>
  creatingMilestone?: boolean
  setCreatingMilestone?: Dispatch<SetStateAction<boolean>>
  refetch?: () => Promise<unknown>
  adminEditMode: boolean
  viewAsOption: ViewAsOption
  formFieldsChanged: boolean
  setFormFieldsChanged: Dispatch<SetStateAction<boolean>>
  saveGoal: boolean
  setSaveGoal: Dispatch<SetStateAction<boolean>>
}

const EditObjectiveForm: FC<EditObjectiveFormProps> = ({
  sidebarState,
  setSidebarState,
  selectedObjective,
  setSelectedObjective,
  goals,
  creatingMilestone,
  setCreatingMilestone,
  refetch,
  adminEditMode,
  formFieldsChanged,
  setFormFieldsChanged,
  saveGoal,
  setSaveGoal,
}) => {
  // COMPONENT STATES
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formFieldsChangedACL, setFormFieldsChangedACL] = useState(false)
  const [previousGoal, setPreviousGoal] = useState(null)

  const currentGoal = useMemo(
    () => goals[selectedObjective],
    [goals, selectedObjective],
  )
  const parentGoal = currentGoal?.parentGoalId
    ? goals[currentGoal.parentGoalId]
    : null

  // Set all the form values
  const setGoalFormValues = (parent: ParentGoal | null) => {
    const startDate = dayjs()
    const dueDate = startDate.add(14, 'd')

    let parentGoalId: number | null
    if (sidebarState === EditFormStates.new) {
      parentGoalId = parent?.id || null
    } else {
      parentGoalId = currentGoal?.parentGoalId || null
    }

    // Set the Form State Values
    return {
      id: currentGoal?.id || null,
      createdBy: currentGoal?.createdBy || null,
      title: currentGoal?.goalTitle || '',
      description: currentGoal?.goalBody || '',
      status: currentGoal?.currentStatus || GoalStatusTypes.inProgress,
      startDate: dayjs(currentGoal?.startDate || startDate).toDate(),
      dueDate: dayjs(currentGoal?.dueDate || dueDate).toDate(),
      startValue: currentGoal?.startValue || 0,
      currentValue: currentGoal?.currentValue || 0,
      targetValue: currentGoal?.targetValue || 0,
      parentGoalId: parentGoalId,
      parentGoal: parent || {
        id: 0,
        title: 'unable to find parent',
      },
      attachedLearnerItemId: currentGoal?.attachedLearnerItemId || null,
      completedDate: currentGoal?.completedDate || null,
      isPrivate: currentGoal?.isPrivate,
    }
  }

  const [learnerCourses, setLearnerCourses] = useState<
    LearnerCourseWithStorageObject[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)

  const [visibleLearnerCategories, setVisibleLearnerCategories] = useState<{
    [key: number]: string
  }>({})

  const [loadLearnerCourses] = useLazyQuery<
    findLearnerCoursesQuery,
    findLearnerCoursesQueryVariables
  >(FIND_LEARNER_COURSES_QUERY, {
    onCompleted: (data) => {
      // Sort learner courses by category name
      const learnerCourseArr = [...data.getLearnerCoursesTaskTypeCount]
      const sortedLearnerCourses = learnerCourseArr.sort((a, b) => {
        return a?.learnerCategory?.name > b?.learnerCategory?.name ? 1 : -1
      })
      setLearnerCourses(sortedLearnerCourses)
      // Create tuple with learner category options
      const visibleLearnerCategoriesTuples = sortedLearnerCourses
        .filter((course) => course?.learnerCategory?.id)
        .map((course) => {
          return [course.learnerCategory.id, course.learnerCategory.name]
        })

      // Create object from tuple for learner category options
      const visibleLearnerCategories: { [key: number]: string } =
        Object.fromEntries(visibleLearnerCategoriesTuples)

      setVisibleLearnerCategories(visibleLearnerCategories)
      setLoading(false)
    },
  })

  const handleFocus = () => {
    if (learnerCourses.length === 0 && !loading) {
      setLoading(true)
      loadLearnerCourses()
    }
  }

  // if the user selects a different goal and there are form changes
  // prompt the user to save the changes
  useEffect(() => {
    if (formFieldsChanged || saveGoal) {
      confirmFieldChanges({
        title: 'You have unsaved changes',
        description: 'Do you want to save?',
      })
        .then(() => {
          // save the goal
          handleFormSubmit(false)
          if (!selectedObjective) {
            // set thing to empty
            setSidebarState(EditFormStates.empty)
          }
        })
        .catch(() => {
          // do nothing
        })
    }
    setFormFieldsChanged(false)
    setSaveGoal(false)
  }, [selectedObjective, saveGoal])

  // State for all fields on the form. This is updated and used to save the data
  const [sidebarFields, setSidebarFields] = useState<GoalsExtended>(
    setGoalFormValues(null),
  )

  // Selected milestone learner course
  const [selectedCourse, setSelectedCourse] =
    useState<LearnerCourseWithStorageObject>(null)

  // Prevent save on initial render
  const [saveCourse, setSaveCourse] = useState(false)

  const [trackLearnerProgress, setTrackLearnerProgress] = useState(false)

  // Current user
  const { currentUser } = useAuth()

  const defaultToastDescription = 'Please try again or reload the page.'

  const updateGoalCache = async (mutationResult: ImproverGoalMemberFields) => {
    const updatedField = 'goals'

    const queryVariables: FindImproverGoalMemberGridQueryVariables = {
      adminEditMode,
    }

    if (!adminEditMode) {
      queryVariables.membershipId = currentUser.membershipData.id
    }

    if (!mutationResult?.parentGoalId) {
      updateCache({
        query: FIND_IMPROVER_GOAL_MEMBER_GRID_QUERY,
        mutationResult,
        updatedField,
        queryVariables,
      })
    } else {
      // if the goal is a milestone then the structure is a bit more complicated
      // and we can't use the boilerplate update cache method
      const existingData = apolloCache.readQuery<
        FindImproverGoalMemberGridQuery,
        FindImproverGoalMemberGridQueryVariables
      >({
        query: FIND_IMPROVER_GOAL_MEMBER_GRID_QUERY,
        variables: queryVariables,
      })

      const parentGoal = existingData[updatedField].find(
        (goal) => goal.id === mutationResult.parentGoalId,
      )

      // are we making a new milestone or deleting one?
      const isDeleteOperation = parentGoal.childGoals.some(
        (goal) => goal.id === mutationResult.id,
      )

      const updatedChildGoals = isDeleteOperation
        ? parentGoal.childGoals.filter((goal) => goal.id !== mutationResult.id)
        : [...parentGoal.childGoals, mutationResult]

      const updatedParentGoal = {
        ...parentGoal,
        childGoals: updatedChildGoals,
      }

      const updatedData: FindImproverGoalMemberGridQuery = {
        ...existingData,
        [updatedField]: existingData[updatedField].map((goal) =>
          goal.id === mutationResult.parentGoalId ? updatedParentGoal : goal,
        ),
      }

      apolloCache.writeQuery<FindImproverGoalMemberGridQuery>({
        query: FIND_IMPROVER_GOAL_MEMBER_GRID_QUERY,
        data: updatedData,
        variables: queryVariables,
      })
    }
  }

  // CREATE GOAL ITEMS
  const [createGoalItemMutation] = useMutation<
    CreateImproverGoalMemberObjFormMutation,
    CreateImproverGoalMemberObjFormMutationVariables
  >(CREATE_GOAL_ITEM, {
    onCompleted: async (item) => {
      setIsLoading(false)
      const newData = item.createImproverGoalMember

      const goToCreateGoal = true

      // Create an ACL record
      const aclResult = await saveACL({
        resourceId: item.createImproverGoalMember.id,
      })

      if (aclResult.length === 0) {
        updateGoalCache(newData)
      } else {
        // if there is acl added then the cache is incorrect and we must refetch
        await refetch()
      }

      // Cleanup
      if (goToCreateGoal) {
        handleCreateFinish(newData.id)
      }

      toast.success(
        `${newData?.parentGoalId ? 'Milestone' : 'Goal'} created successfully`,
      )
    },
    onError: () => {
      setIsLoading(false)
      toast.error(
        <ToastMessage
          message="There was an error creating your goal"
          description={defaultToastDescription}
        />,
        {
          duration: 5000,
          className: 'flex-column',
        },
      )
    },
  })

  const [createPersonalGoalItemMutation] = useMutation<
    CreatePersonalImproverGoalMemberObjFormMutation,
    CreatePersonalImproverGoalMemberObjFormMutationVariables
  >(CREATE_PERSONAL_GOAL_ITEM, {
    onCompleted: async (item) => {
      const newData = item.createPersonalImproverGoalMember
      setIsLoading(false)
      handleCreateFinish(newData.id)
      toast.success(
        `${newData?.parentGoalId ? 'Milestone' : 'Goal'} created successfully`,
      )
    },
    onError: (error) => {
      setIsLoading(false)
      toast.error(
        <ToastMessage
          message="There was an error creating your goal."
          description={error.message}
        />,
        {
          duration: 5000,
          className: 'flex-column',
        },
      )
    },
    update: (_, { data: { createPersonalImproverGoalMember } }) =>
      updateGoalCache(createPersonalImproverGoalMember),
  })

  const [findCourseById] = useLazyQuery(FIND_COURSE_BY_ID_QUERY, {
    onCompleted: (data) => {
      setSelectedCourse(data.findCourseById)
    },
  })

  async function createGoalItem(args) {
    // Set loading state
    setIsLoading(true)

    // ACL changed - save the ACL
    if (inputChangedACL && sidebarState === EditFormStates.edit) {
      await saveACL({
        resourceId: currentGoal?.id ?? null,
      })
    }

    if (adminEditMode) {
      // IF admin mode, create goal and handle ACL
      await createGoalItemMutation({
        variables: {
          adminEditMode,
          input: {
            goalTitle: args.data.goalTitle,
            goalBody: args.data.goalBody,
            isComplete: args.data.isComplete,
            startValue: args.data.startValue,
            currentValue: args.data.currentValue,
            targetValue: args.data.targetValue,
            status: args.data.status,
            startDate: args.data.startDate,
            dueDate: args.data.dueDate,
            completedDate: args.data.completedDate,
            parentGoalId: args.data.parentGoalId,
            attachedLearnerItemId: args.data.attachedLearnerItemId,
            isPrivate: args.data.isPrivate,
            clientId: currentUser.parentData.id,
          },
        },
      })
    } else {
      // Handle Personal Goal creating
      await createPersonalGoalItemMutation({
        variables: {
          input: {
            goalTitle: args.data.goalTitle,
            goalBody: args.data.goalBody,
            isComplete: args.data.isComplete,
            startValue: args.data.startValue,
            currentValue: args.data.currentValue,
            targetValue: args.data.targetValue,
            status: args.data.status,
            startDate: args.data.startDate,
            dueDate: args.data.dueDate,
            completedDate: args.data.completedDate,
            parentGoalId: args.data.parentGoalId,
            attachedLearnerItemId: args.data.attachedLearnerItemId,
            isPrivate: args.data.isPrivate,
            clientId: currentUser.parentData.id,
          },
        },
      })
    }
  }

  // DELETE GOAL ITEMS
  const [deleteGoalItemMutation] = useMutation<
    DeleteImproverGoalMember,
    DeleteImproverGoalMemberVariables
  >(DELETE_GOAL_ITEM, {
    onCompleted: async () => {
      toast.success('Goal deleted successfully')
      setFormFieldsChanged(false)
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error deleting your goal"
          description={defaultToastDescription}
        />,
        {
          duration: 5000,
          className: 'flex-column',
        },
      )
    },
    update: (_, { data: { deleteImproverGoalMember } }) =>
      updateGoalCache(deleteImproverGoalMember),
  })

  async function deleteGoalItem(id: number) {
    await deleteGoalItemMutation({
      variables: {
        id,
        adminEditMode,
      },
    })
  }

  // UPDATE GOAL ITEMS
  const [updateGoalItemMutation] = useMutation<
    UpdateImproverGoalMemberObjFormMutation,
    UpdateImproverGoalMemberObjFormMutationVariables
  >(UPDATE_GOAL_ITEM, {
    onCompleted: async (_item) => {
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      toast.error(
        <ToastMessage
          message="There was an error updating your goal"
          description={defaultToastDescription}
        />,
        {
          duration: 5000,
          className: 'flex-column',
        },
      )
    },
  })

  const [updateGoalItemStatusMutation] = useMutation<
    UpdateImproverGoalMemberObjFormMutation,
    UpdateImproverGoalMemberObjFormMutationVariables
  >(UPDATE_GOAL_ITEM, {
    onCompleted: (_item) => {
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      toast.error(
        <ToastMessage
          message="There was an error updating your goal"
          description={defaultToastDescription}
        />,
        {
          duration: 5000,
          className: 'flex-column',
        },
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindImproverGoalMemberGridQuery'],
  })

  async function updateGoalItem(args: { data: any }, closeAll: boolean) {
    const id = args.data.id
    let didUpdate = false

    // check if acl has been updated
    if (formFieldsChangedACL) {
      try {
        await saveACL({
          resourceId: id ?? null,
        })
        await refetch()
        didUpdate = true
      } catch (error) {
        toast.error(
          <ToastMessage
            message="There was an error modifying the assignees of this goal"
            description={defaultToastDescription}
          />,
          {
            duration: 5000,
            className: 'flex-column',
          },
        )
      }
    }
    // set back to false after saving
    setFormFieldsChangedACL(false)

    // Check if any details changed, only save when there is a difference
    if (
      currentGoal.goalTitle !== args.data.goalTitle ||
      currentGoal.goalBody !== args.data.goalBody ||
      currentGoal.startValue !== args.data.startValue ||
      currentGoal.currentValue !== args.data.currentValue ||
      currentGoal.targetValue !== args.data.targetValue ||
      currentGoal.status !== args.data.status ||
      currentGoal.isPrivate !== args.data.isPrivate ||
      currentGoal.attachedLearnerItemId !== args.data.attachedLearnerItemId ||
      (args.data.startDate !== null &&
        new Date(args.data.startDate).getTime() !==
          new Date(currentGoal.startDate).getTime()) ||
      (args.data.dueDate !== null &&
        new Date(args.data.dueDate).getTime() !==
          new Date(currentGoal.dueDate).getTime())
    ) {
      // Make some changes to the DB
      setIsLoading(true)

      const { id, parentGoalId: _parentGoalId, ...inputArgs } = args.data

      await updateGoalItemMutation({
        variables: {
          id: id,
          input: {
            ...inputArgs,
          },
          adminEditMode: adminEditMode,
        },
      }).then(() => {
        didUpdate = true
      })
    } else {
      if (closeAll) {
        // User clicked the grey box, they want to close everything
        setSidebarState(EditFormStates.empty)
        setSelectedObjective(null)
        setCreatingMilestone(false)
      } else {
        if (sidebarFields.parentGoalId) {
          // Go back to the parent only if saving milestone
          handleBackToParent(sidebarFields.parentGoalId)
        } else {
          setCreatingMilestone(false)
        }
      }
    }
    if (didUpdate) {
      toast.success('Goal updated successfully')
    }
    setIsLoading(false)
    setPreviousGoal(null)
  }

  const { trackEvent } = useAnalytics()

  // HANDLE INPUT CHANGE
  const handleInputChange = (
    field: keyof GoalsExtended,
    value: string | number | null | undefined | boolean | Assignee[],
  ) => {
    // changes detected
    setFormFieldsChanged(true)

    // Lets get ready to save the data, then update the state values
    setSidebarFields((sidebarFields) => ({ ...sidebarFields, [field]: value }))

    // Check the date for status - needs to update when dueDate changes
    if (sidebarState === EditFormStates.edit && field === 'dueDate') {
      const today = dayjs()
      const dueDate = dayjs(value as dayjs.ManipulateType)

      if (
        sidebarFields.status === GoalStatusTypes.inProgress ||
        sidebarFields.status === GoalStatusTypes.overdue
      ) {
        if (dueDate.isBefore(today)) {
          handleInputChange('status', GoalStatusTypes.overdue)
        } else {
          handleInputChange('status', GoalStatusTypes.inProgress)
        }
      }
    }

    if (sidebarState === EditFormStates.edit) {
      let labelString: string

      if (typeof value === 'string') {
        labelString = value.toString().replace(/(\r\n|\n|\r)/gm, '')
      } else if (typeof value === 'object' && value.length) {
        const names = []

        for (let i = 0; i < value.length; i++) {
          names.push(value[i].name)
        }

        labelString = names.join(', ')
      } else {
        labelString = value?.toString()
      }

      trackEvent('Goals', `Change ${field}`, { newValue: labelString })
    }
  }

  const updateParentStatus = async (status) => {
    if (parentGoal) {
      const parentGoalStatus = parentGoal.status
      if (parentGoalStatus !== status) {
        await updateGoalItemStatusMutation({
          variables: {
            id: parentGoal.id,
            input: {
              status: status,
              isComplete: status === GoalStatusTypes.completed,
            },
            adminEditMode: adminEditMode,
          },
        })
      }
    }
  }

  // HANDLE FORM SUBMIT
  const handleFormSubmit = (event, closeAll = false) => {
    if (event) {
      event.preventDefault()
    }
    setIsLoading(true)

    // Set completed date
    let completedDate: dayjs.Dayjs | null = dayjs(sidebarFields?.completedDate)

    // IF the completed date changes to "Completed" and it is different from the existing record. - Update the completed date.
    if (
      sidebarFields?.status === GoalStatusTypes.completed &&
      currentGoal?.status !== GoalStatusTypes.completed
    ) {
      completedDate = dayjs()
    }

    // const completedDate = sidebarFields?.completedDate ===  dayjs()
    const startDate = dayjs()
    const dueDate = startDate.add(14, 'd')

    // this is the data from the state. any changes will be set from here
    const args = {
      data: {
        id: sidebarFields.id,
        goalTitle: sidebarFields.title,
        goalBody: sidebarFields.description,
        isComplete: sidebarFields.status === GoalStatusTypes.completed,
        startValue: sidebarFields.startValue || 0,
        currentValue: sidebarFields.currentValue || 0,
        targetValue: sidebarFields.targetValue || 0,
        status: sidebarFields.status || GoalStatusTypes.inProgress,
        startDate: sidebarFields.startDate ?? startDate,
        dueDate: sidebarFields.dueDate ?? dueDate,
        attachedLearnerItemId: sidebarFields?.attachedLearnerItemId ?? null,
        completedDate: completedDate,
        isPrivate: sidebarFields.isPrivate,
        parentGoalId:
          sidebarFields.parentGoal.id === 0
            ? null
            : sidebarFields.parentGoal.id,
        // Parent ID can be 0 or NULL to represent NO PARENT. I had problems conditionally displaying content with NULL, so allowed 0 as a fallback default. No goal SHOULD have id of 0... why would it. I may need to fix this.
      },
    }

    // Handle the DB actions
    if (sidebarState === EditFormStates.new) {
      // Create a new record
      createGoalItem(args)
    } else if (sidebarState === EditFormStates.edit) {
      // Update the goal details
      updateGoalItem(args, closeAll)
    }
    setFormFieldsChanged(false)
  }

  // Delete the Item and reset the sidebar states
  const handleDeleteGoalItem = async (goalId: number) => {
    await deleteGoalItem(goalId)
    // Reset the sidebar
    setSidebarState(EditFormStates.empty)
    setSelectedObjective(null)
  }

  const handleCreateFinish = (objectiveId: number) => {
    if (!objectiveId) {
      // Go back to main menu
      setSidebarState(EditFormStates.empty)
      setSelectedObjective(null)

      setCreatingMilestone(false)
    } else {
      // Go back to parent
      setSelectedObjective(objectiveId)
      setSidebarState(EditFormStates.edit)
      setCreatingMilestone(false)
    }
  }

  // Reset the sidebar states and prefill data with saved parent values
  const handleBackToParent = (parentId) => {
    setSidebarState(EditFormStates.edit)
    setSelectedObjective(parentId)
    setCreatingMilestone(false)
  }

  // Reset sidebar state and clear form and save parent values
  const handleAddKeyMilestone = () => {
    setSidebarState(EditFormStates.new)

    setSelectedObjective(null)
  }

  // ON selected objective change, reset the form fields
  useEffect(() => {
    // This helps set a parent id for when creating a new milestone.
    const parentData = {
      id: sidebarFields.id || currentGoal?.parentGoalId || 0,
      title: sidebarFields.title ? sidebarFields.title : '',
    }
    const formValues = setGoalFormValues(parentData)
    setSidebarFields(formValues)
  }, [currentGoal])

  useEffect(() => {
    if (!sidebarFields?.attachedLearnerItemId) return

    if (learnerCourses.length === 0) {
      findCourseById({
        variables: {
          id: sidebarFields.attachedLearnerItemId,
        },
      })
    } else {
      setSelectedCourse(
        learnerCourses.find(
          (course) => course.id === sidebarFields.attachedLearnerItemId,
        ),
      )
    }

    setTrackLearnerProgress(false)
  }, [sidebarFields?.attachedLearnerItemId])

  // Add a new key milestone when the user clicks the special button
  // The special button sets the parent, then changes to a new milestone.
  // This is just a simulated action.. as if the user would click to edit the parent, then click add milestone.
  // Instead they are clicking from the separate add milestone button.
  useEffect(() => {
    if (creatingMilestone === true) handleAddKeyMilestone()
  }, [creatingMilestone])

  // Set Debounce Timeout
  // Creating a new goal requires immediate state updates, editing can use debounce to
  // Having number 0 for "new" goals turns falsy and uses the fallback of 750ms.. use 1 instead
  const debounceTimeout: number = sidebarState === EditFormStates.new ? 1 : 750

  // Init Confirm
  const confirmDelete = useConfirm()
  const confirmCompletedDateChange = useConfirm()
  const confirmCompleteGoal = useConfirm()
  const confirmFieldChanges = useConfirm()

  // User can edit
  const userCanEditGoal =
    adminEditMode || sidebarFields?.createdBy === currentUser.userData.id

  const disableFields = sidebarState === EditFormStates.edit && !userCanEditGoal

  const currentUserIsGoalCreator =
    sidebarFields?.createdBy === currentUser.userData.id

  const checkStatusAndHandleInputChange = (toggleButtonGroupValue) => {
    if (toggleButtonGroupValue === GoalStatusTypes.inProgress) {
      const today = dayjs()
      const dueDate = dayjs(sidebarFields.dueDate as dayjs.ManipulateType)

      if (dueDate.isBefore(today)) {
        handleInputChange('status', GoalStatusTypes.overdue)
      } else {
        handleInputChange('status', GoalStatusTypes.inProgress)
      }
    } else {
      handleInputChange('status', toggleButtonGroupValue)
    }
  }

  // Prompt user confirmation when changing the status - the completed date on the goal will change.
  const handleChangeStatus = (toggleButtonGroupValue: string) => {
    // If the new status is not complete, and the old status is complete, prompt the user.

    // set formfield changed as status changed
    setFormFieldsChanged(true)

    if (
      currentGoal?.completedDate !== null &&
      toggleButtonGroupValue !== GoalStatusTypes.completed &&
      currentGoal?.status === GoalStatusTypes.completed
    ) {
      confirmCompletedDateChange({
        title: 'Are you sure you want to change the status?',
        description:
          'Changing the status from Complete to In Progress/Blocked will reset the saved completion date.',
      })
        .then(() => {
          /* yes selected */
          checkStatusAndHandleInputChange(toggleButtonGroupValue)
          if (parentGoal) {
            return updateParentStatus(
              parentGoal?.status === GoalStatusTypes.completed
                ? GoalStatusTypes.inProgress
                : parentGoal.status,
            )
          }
        })
        .catch(() => {
          /* no selected */
        })
    } else {
      // If overdue, set to overdue
      checkStatusAndHandleInputChange(toggleButtonGroupValue)
      if (
        toggleButtonGroupValue === GoalStatusTypes.completed &&
        parentGoal &&
        parentGoal.status !== GoalStatusTypes.completed
      ) {
        const allMilestonesComplete = parentGoal.childGoals.every(
          (childGoal) =>
            childGoal.id === currentGoal.id ||
            childGoal.status === GoalStatusTypes.completed,
        )

        if (allMilestonesComplete) {
          return confirmCompleteGoal({
            title: 'Complete the goal?',
            description:
              'You have completed all milestones for this goal. Would you like to complete the goal as well?',
          })
            .then(() => {
              /* yes selected */
              return updateParentStatus(GoalStatusTypes.completed)
            })
            .catch(() => {
              /* no selected */
            })
        }
      }
    }
  }

  // Update sidebarfields on learner course item change
  useEffect(() => {
    if (saveCourse) {
      handleInputChange('attachedLearnerItemId', selectedCourse?.id)
    }
    setSaveCourse(false)
  }, [selectedCourse])

  // Init ACL
  const {
    AccessControlList,
    saveACL,
    inputChangedACL,
    isLoading: isLoadingACL,
  } = useACL({
    resourceType: 'ImproverGoalMember' as TableName,
    resourceId: previousGoal ? previousGoal : currentGoal?.id,
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
    contentPrivateDefault: true,
  })

  useEffect(() => {
    if (!currentGoal?.id) {
      setPreviousGoal(currentGoal?.id)
    }
    if (currentGoal) {
      setPreviousGoal(currentGoal?.id)
    }

    setFormFieldsChanged(false)
  }, [currentGoal])

  useEffect(() => {
    setFormFieldsChanged(false)
  }, [isLoadingACL])

  // check for acl changes
  useEffect(() => {
    if (inputChangedACL && sidebarState === EditFormStates.edit) {
      setFormFieldsChanged(true)
      setFormFieldsChangedACL(true)
    }
  }, [inputChangedACL])

  // after acl loads, reset the form fields changed as it is not a change
  useEffect(() => {
    setFormFieldsChanged(false)
  }, [isLoadingACL])

  if (isLoadingACL) return <Loading />

  return (
    <>
      <form
        className="flex flex-col grow flex-1 overflow-y-auto w-full pb-10"
        onSubmit={(e) => {
          handleFormSubmit(e)
        }}
      >
        <div>
          <div className="mb-4 flex justify-between bg-gray-100 p-3 items-center">
            <span className="flex items-center max-w-[320px]">
              <GoalTargetIcon
                sizeInPixels={12}
                fillColour={'#ffffff'}
                className={
                  'mr-2 min-w-[26px] min-h-[26px] p-1 rounded-md bg-indigo-600'
                }
              />
              <span className="w-full">
                {sidebarFields?.parentGoalId === null ? (
                  sidebarFields?.parentGoal.id !== 0 &&
                  sidebarState === EditFormStates.new ? (
                    <span className="flex items-center text-sm text-gray-500 ml-1">
                      <Button
                        className="text-gray-500 hover:!bg-gray-100 max-w-[280px]"
                        startIcon={<ChevronRightIcon className="w-5 h-5" />}
                        variant="text"
                        onClick={(e) => {
                          e.preventDefault()
                          handleBackToParent(sidebarFields.parentGoal.id)
                        }}
                      >
                        <span className="break-words line-clamp-1 pt-0.5">
                          {sidebarFields.parentGoal.title}
                        </span>
                      </Button>
                    </span>
                  ) : (
                    <span className="text-[13px] text-gray-700 ml-0.5 tracking-wider">
                      <span className="uppercase">Goal</span>
                    </span>
                  )
                ) : (
                  <span className="flex items-center text-sm gap-2 text-gray-500 ml-1">
                    <Button
                      className="text-gray-500 hover:!bg-gray-100 max-w-[280px]"
                      startIcon={<ChevronLeftIcon className="w-5 h-5" />}
                      variant="text"
                      onClick={(e) => {
                        e.preventDefault()
                        if (
                          !isLoading &&
                          sidebarState === EditFormStates.edit
                        ) {
                          // handleformsubmit( false means that no form submit event is passed )
                          handleFormSubmit(false)
                        } else {
                          handleBackToParent(sidebarFields.parentGoal.id)
                        }
                      }}
                    >
                      <span className="break-words line-clamp-1 pt-0.5">
                        {goals[sidebarFields?.parentGoalId]?.goalTitle}
                      </span>
                    </Button>
                  </span>
                )}
              </span>
            </span>

            <span className="flex justify-end flex-col items-end">
              {sidebarState === EditFormStates.edit && userCanEditGoal && (
                <div className="flex">
                  <Button
                    variant="contained"
                    className="mr-2"
                    onClick={() => handleFormSubmit(false)}
                    loading={isLoading}
                    disabled={!formFieldsChanged}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={(e) => {
                      e.preventDefault()
                      confirmDelete({
                        title: !sidebarFields.parentGoalId
                          ? 'Delete Goal'
                          : 'Delete Milestone',
                        description:
                          'Are you sure you want to delete this record?',
                      })
                        .then(() => {
                          setIsLoading(true)
                          handleDeleteGoalItem(sidebarFields.id)
                          if (!sidebarFields.parentGoalId) {
                            handleBackToParent(sidebarFields.parentGoalId)
                          }
                          setIsLoading(false)
                        })
                        .catch(() => {
                          // Do Nothing - user cancelled
                          // Empty catch avoids error logged to console
                        })
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </span>
          </div>

          <div className="px-4">
            <Accordion
              title="Details"
              expanded={true}
              data-testid="goal-edit-details-accordion"
            >
              <FormInputRow label="Title">
                <DebounceTextInput
                  onChange={(value) => {
                    return value !== sidebarFields.title
                      ? handleInputChange('title', value)
                      : null
                  }}
                  debounceTimeout={debounceTimeout}
                  defaultValue={sidebarFields.title}
                  name="title"
                  setKey={selectedObjective + 'title'}
                  isRequired={true}
                  disabled={disableFields}
                />
              </FormInputRow>

              <FormSpacer />
              <FormInputRow label="Description">
                <RichTextEditor
                  name="description"
                  id="goal-edit-description"
                  defaultValue={sidebarFields.description}
                  onChange={(value) => handleInputChange('description', value)}
                  disabled={disableFields}
                  debounce={sidebarState === EditFormStates.new ? 0 : 800}
                  className="max-h-[200px]"
                />
              </FormInputRow>

              {!adminEditMode &&
                sidebarFields.parentGoalId === null &&
                (currentUserIsGoalCreator ||
                  sidebarState === EditFormStates.new) && (
                  <FormInputRow>
                    <div className="px-4 py-3 border border-gray-300 rounded ">
                      <div className="flex flex-row gap-4 items-center justify-between text-gray-700">
                        <div className="flex flex-col gap-0.5">
                          <span>Private Goal</span>
                          <span className="text-xs text-gray-400">
                            This goal will only be visible to you.
                          </span>
                        </div>
                        <Switch
                          checked={sidebarFields.isPrivate || false}
                          name="goalPrivate"
                          onChange={(event) => {
                            handleInputChange('isPrivate', event.target.checked)
                          }}
                        />
                      </div>
                    </div>
                  </FormInputRow>
                )}
            </Accordion>

            {adminEditMode && (
              <>
                <FormSpacer />
                <Accordion
                  title="Access Control"
                  expanded={false}
                  data-testid="goal-edit-access-accordion"
                >
                  <div className="pt-2">
                    <AccessControlList
                      membershipCardTitle={
                        'Assign ' +
                        (currentGoal?.parentGoalId ? 'Milestone' : 'Goal')
                      }
                      membershipCardText={
                        'By assigning to Groups and/or Members, you are giving them access to view this ' +
                        (currentGoal?.parentGoalId ? 'Milestone.' : 'Goal.')
                      }
                      groupsLabelText="Visible to Groups"
                      membersLabelText="Assign to Members"
                    />
                  </div>
                </Accordion>
              </>
            )}
            <FormSpacer />
            {sidebarState === EditFormStates.edit &&
              sidebarFields.parentGoalId === null &&
              userCanEditGoal && (
                <>
                  <Accordion
                    title={
                      <div>
                        Milestones{' '}
                        <Chip
                          className="ml-2"
                          color="primary"
                          variant="outlined"
                          size="small"
                          label={currentGoal?.childGoals?.length}
                        />
                      </div>
                    }
                    expanded={false}
                    data-testid="goal-edit-milestones-accordion"
                  >
                    <div className="flex-1 px-5">
                      <div className="flex gap-2 items-center mt-4">
                        <Button
                          fullWidth={false}
                          className="grow gap-2"
                          startIcon={
                            <PlusIcon
                              className="h-5 w-5 text-indigo-800 bg-indigo-200 rounded-full "
                              aria-hidden="true"
                            />
                          }
                          onClick={handleAddKeyMilestone}
                          buttonDataTestId="add-milestone-to-objective-button"
                        >
                          Add a milestone
                        </Button>
                      </div>
                      <div className="flex gap-2 items-start mt-4">
                        <div className="w-full mt-4">
                          {currentGoal?.childGoals?.length > 0 &&
                            currentGoal?.childGoals.map((childGoal) => (
                              <Button
                                onClick={() => {
                                  setSelectedObjective(childGoal.id)
                                }}
                                variant="outlined"
                                className="!border !border-gray-300 bg-white rounded w-full text-left flex items-center justify-between px-4 py-1 mb-2 hover:!bg-gray-100 !text-gray-800 overflow-hidden"
                                key={childGoal.id}
                              >
                                <span className="line-clamp-1 w-full break-words">
                                  {childGoal.goalTitle}
                                </span>
                                <span className="ml-5 flex flex-row items-center gap-[10px]">
                                  <span
                                    className={`w-2 h-2 rounded-lg block ${
                                      StatusBGColorsDark[
                                        childGoal?.currentStatus
                                      ]
                                    }`}
                                  ></span>
                                  <EllipsisHorizontalIcon className="w-4 h-4 block" />
                                </span>
                              </Button>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <FormSpacer />
                </>
              )}
            <Accordion
              title="Progress"
              expanded={false}
              data-testid="goal-edit-progress-accordion"
            >
              <FormInputRow label="Status">
                <ToggleButtonGroup
                  sx={{ primary: '#ff0000' }}
                  value={
                    sidebarFields.status === GoalStatusTypes.overdue ||
                    sidebarFields.status === undefined
                      ? GoalStatusTypes.inProgress
                      : sidebarFields.status
                  }
                  exclusive
                  onChange={(
                    _: React.MouseEvent<HTMLElement>,
                    toggleButtonGroupValue: string,
                  ) => {
                    if (toggleButtonGroupValue) {
                      handleChangeStatus(toggleButtonGroupValue)
                    }
                  }}
                  disabled={disableFields}
                  className="w-full flex"
                >
                  <ToggleButton
                    className="w-1/3"
                    name="buttonCompleted"
                    value={GoalStatusTypes.completed}
                    size="small"
                    disableRipple
                    sx={{
                      '&.Mui-selected': {
                        background: '#16a34a',
                        color: '#fff',
                        '&:hover': {
                          background: '#16a34a',
                        },
                      },
                      '&:focus': {
                        outline: '2px solid #ccc',
                      },
                    }}
                  >
                    {NewGoalStatusTypes.completed}
                  </ToggleButton>
                  <ToggleButton
                    className="w-1/3"
                    name="buttonInProgress"
                    value={GoalStatusTypes.inProgress}
                    size="small"
                    disableRipple
                    sx={{
                      '&.Mui-selected': {
                        background: '#2563eb',
                        color: '#fff',
                        '&:hover': {
                          background: '#2563eb',
                        },
                      },
                      '&:focus': {
                        outline: '2px solid #ccc',
                      },
                    }}
                  >
                    {NewGoalStatusTypes.inProgress}
                  </ToggleButton>
                  <ToggleButton
                    className="w-1/3"
                    name="buttonBlocked"
                    value={GoalStatusTypes.blocked}
                    size="small"
                    disableRipple
                    sx={{
                      '&.Mui-selected': {
                        background: '#4b5563',
                        color: '#fff',
                        '&:hover': {
                          background: '#4b5563',
                        },
                      },
                      '&:focus': {
                        outline: '2px solid #ccc',
                      },
                    }}
                  >
                    {NewGoalStatusTypes.blocked}
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormInputRow>

              <FormSpacer />

              {/* START DATE, END DATE */}
              <FormInputRow label="">
                <div className="flex gap-2 w-full">
                  <div className="flex-1 mw-50">
                    <span className="block text-xs text-gray-400 uppercase mb-1">
                      Start Date
                    </span>
                    <DatePicker
                      className={` ${disableFields && 'bg-gray-100'} `}
                      value={dayjs(sidebarFields.startDate)}
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          id: 'startDate',
                          name: 'startDate',
                        },
                      }}
                      onChange={(newValue) => {
                        if (
                          newValue &&
                          newValue['$d'].toString() !== 'Invalid Date'
                        ) {
                          handleInputChange('startDate', newValue['$d'])
                        }
                      }}
                      disabled={disableFields}
                    />
                  </div>
                  <div className="flex-1 mw-50">
                    <span className="block text-xs text-gray-400 uppercase mb-1">
                      End Date
                    </span>
                    <DatePicker
                      className={` ${disableFields && 'bg-gray-100'} `}
                      value={dayjs(sidebarFields.dueDate)}
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          id: 'dueDate',
                          name: 'dueDate',
                        },
                      }}
                      onChange={(newValue) => {
                        if (
                          newValue &&
                          newValue['$d'].toString() !== 'Invalid Date'
                        ) {
                          handleInputChange('dueDate', newValue['$d'])
                        }
                      }}
                      disabled={disableFields}
                    />
                  </div>
                </div>
              </FormInputRow>

              {/* Start, Current, Target */}
              {!sidebarFields?.attachedLearnerItemId &&
                currentGoal?.templateType !== ProgressionTypes.simple &&
                !trackLearnerProgress && (
                  <>
                    <FormInputRow label="">
                      <div className="flex gap-2 w-full">
                        <div className="flex-1 mw-50">
                          <span className="block text-xs text-gray-400 uppercase mb-1">
                            Start Value
                          </span>
                          <DebounceNumberInput
                            onChange={(value) => {
                              return value !== sidebarFields.startValue
                                ? handleInputChange('startValue', value)
                                : null
                            }}
                            debounceTimeout={debounceTimeout}
                            defaultValue={sidebarFields.startValue}
                            name="startValue"
                            setKey={selectedObjective + 'startValue'}
                            disabled={disableFields}
                          />
                        </div>
                        <div className="flex-1 mw-50">
                          <span className="block text-xs text-gray-400 uppercase mb-1">
                            Current Value
                          </span>
                          <DebounceNumberInput
                            onChange={(value) => {
                              return value !== sidebarFields.currentValue
                                ? handleInputChange('currentValue', value)
                                : null
                            }}
                            debounceTimeout={debounceTimeout}
                            defaultValue={sidebarFields.currentValue}
                            name="currentValue"
                            setKey={selectedObjective + 'currentValue'}
                            disabled={disableFields}
                          />
                        </div>
                        <div className="flex-1 mw-50">
                          <span className="block text-xs text-gray-400 uppercase mb-1">
                            Target Value
                          </span>
                          <DebounceNumberInput
                            onChange={(value) => {
                              return value !== sidebarFields.targetValue
                                ? handleInputChange('targetValue', value)
                                : null
                            }}
                            debounceTimeout={debounceTimeout}
                            defaultValue={sidebarFields.targetValue}
                            name="targetValue"
                            setKey={selectedObjective + 'targetValue'}
                            disabled={disableFields}
                          />
                        </div>
                      </div>
                    </FormInputRow>
                  </>
                )}
            </Accordion>
            <FormSpacer />
            {/* Learner Course */}
            {(sidebarFields?.attachedLearnerItemId || trackLearnerProgress) && (
              <>
                <FormInputRow
                  label={
                    adminEditMode ? (
                      <Tooltip
                        title={
                          'Please ensure the course has the required permissions to allow visibility for Users and Groups'
                        }
                        enterDelay={200}
                        enterNextDelay={200}
                      >
                        <div className=" flex items-center">
                          Learner Course
                          <InformationCircleIcon className="text-blue-500 h-4 w-4 ml-2" />
                        </div>
                      </Tooltip>
                    ) : (
                      'Learner Course'
                    )
                  }
                >
                  <>
                    <Autocomplete
                      id="goal-edit-learner-course"
                      data-testid="goal-edit-learner-course"
                      size="small"
                      loading={loading}
                      disabled={
                        !(
                          adminEditMode
                          // New Milestone
                          // Milestone created by current user
                        )
                      }
                      key={
                        sidebarFields?.id +
                        '-' +
                        sidebarFields?.attachedLearnerItemId
                      }
                      options={learnerCourses}
                      groupBy={(option: LearnerCourseWithStorageObject) =>
                        option.learnerCategory.id.toString()
                      }
                      renderGroup={(params) => {
                        return (
                          <li key={params.key}>
                            <p className="p-2 px-4 bg-gray-100 text-gray-600 italic">
                              {visibleLearnerCategories[params.group]}
                            </p>
                            <ul>{params.children}</ul>
                          </li>
                        )
                      }}
                      getOptionLabel={(
                        option: LearnerCourseWithStorageObject,
                      ) =>
                        (option.learnerCategory.isGlobal ? '(Global) ' : '') +
                        option.name
                      }
                      renderOption={(
                        props,
                        option: LearnerCourseWithStorageObject,
                      ) => {
                        return (
                          <li {...props}>
                            {option.learnerCategory.isGlobal && (
                              <GlobeAltIcon
                                className={
                                  '-ml-0.5 mr-1.5 h-5 w-5 text-green-400'
                                }
                              />
                            )}
                            {option.name}
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Course"
                          onFocus={handleFocus}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      value={selectedCourse}
                      onChange={(
                        _,
                        newValue: LearnerCourseWithStorageObject,
                      ) => {
                        setSaveCourse(true)
                        setSelectedCourse(newValue)
                        setFormFieldsChanged(true)
                      }}
                      isOptionEqualToValue={(
                        option: LearnerCourseWithStorageObject,
                        value: LearnerCourseWithStorageObject,
                      ) => {
                        return option.id === value?.id
                      }}
                    />
                    <FormSpacer />
                    {!sidebarFields?.attachedLearnerItemId && (
                      <div className="p-10 bg-gray-100 rounded text-gray-400 grid place-items-center">
                        No Course Selected
                      </div>
                    )}
                    {sidebarFields?.attachedLearnerItemId && (
                      <CourseProgressPreview
                        learnerCourse={selectedCourse}
                        percentProgress={currentGoal?.percentProgress}
                      />
                    )}
                  </>
                </FormInputRow>
                <FormSpacer />
              </>
            )}
            {sidebarFields?.parentGoalId &&
              !sidebarFields?.attachedLearnerItemId &&
              !trackLearnerProgress &&
              adminEditMode && (
                <>
                  <FormInputRow label="Learner Course">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setTrackLearnerProgress(true)
                        trackEvent('Goals', 'Click Link To Course')
                      }}
                      buttonDataTestId="goals-edit-obj-form-link-to-course-button"
                    >
                      Link to Course
                    </Button>
                  </FormInputRow>
                  <FormSpacer />
                </>
              )}
            {sidebarState === EditFormStates.new && (
              <Button
                disabled={isLoading}
                loading={isLoading}
                buttonDataTestId="goals-edit-obj-form-submit"
                type="submit"
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  )
}

export default EditObjectiveForm
