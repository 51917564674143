import { Dispatch, FC, SetStateAction } from 'react'

import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'

import GoalTargetIcon from 'src/components/Goals/GoalTargetIcon/GoalTargetIcon'
import TrackItemCard from 'src/components/Library/TrackItemCard/TrackItemCard'

import { ProgressionTypes } from '../../constants'
import { formatDuration } from '../../helpers'
import { GoalTemplate } from '../TemplatesCell'

import TemplatePreviewHeader from './TemplatePreviewHeader'
import TemplatePreviewMilestoneItems from './TemplatePreviewMilestoneItems'

interface TemplatesPreviewProps {
  trackTemplate: GoalTemplate | null
  selectedTemplateId?: number | null
  setSelectedTemplateId?: Dispatch<SetStateAction<number | null>>
  isLoading?: boolean
  createGoalTemplate?: (parentGoalId?: number) => void
  hideObjective?: boolean
}

const TemplatesPreview: FC<TemplatesPreviewProps> = ({
  trackTemplate,
  selectedTemplateId,
  setSelectedTemplateId,
  createGoalTemplate,
  isLoading,
  hideObjective = false,
}) => {
  // Check if the template is the current selected template
  const templateIsSelected =
    setSelectedTemplateId && trackTemplate?.id === selectedTemplateId

  // Check if the action is provided and push to onclick
  // a null return prevents any hover effects/actions
  const templateOnClick = setSelectedTemplateId
    ? () => {
        setSelectedTemplateId(trackTemplate?.id)
      }
    : null

  // Conditionally show/hide milestones based on parent type
  const isMilestoneType =
    trackTemplate?.templateType === ProgressionTypes.milestone

  const templateDuration = formatDuration(
    trackTemplate?.dueDateNumber,
    trackTemplate?.dueDateFormat,
  )

  // Timeline Label Classes
  const dotClasses =
    'bg-transparent shadow-none text-xs uppercase text-gray-500 border-0 w-[60px] text-center block'

  return (
    <div
      className={`pb-6 ${!templateOnClick && 'px-6 pt-6'} ${
        hideObjective && '!pt-0'
      }`}
    >
      {templateOnClick && (
        <TemplatePreviewHeader
          templateId={trackTemplate?.id}
          isMilestoneType={isMilestoneType}
          createGoalTemplate={createGoalTemplate}
          isLoading={isLoading}
        />
      )}

      {!hideObjective && (
        <TrackItemCard
          variant="objective"
          title="Goal"
          subheader={trackTemplate?.goalTitle}
          isSelected={templateIsSelected}
          onClick={templateOnClick}
          headerIcon={
            <GoalTargetIcon sizeInPixels={26} className="text-indigo-600" />
          }
          duration={templateDuration}
          className={`${templateIsSelected ? 'ring ring-indigo-600' : ''}`}
        />
      )}
      {isMilestoneType && trackTemplate?.orderedMilestones.length > 0 && (
        <Timeline
          className="p-0 pr-2"
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {trackTemplate?.orderedMilestones.map((startDay, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot className={dotClasses}>
                    {startDay?.dayNumber === 0
                      ? 'Start'
                      : 'Day ' + startDay?.dayNumber}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className="p-0 pb-2 pt-14">
                  <TemplatePreviewMilestoneItems
                    milestones={startDay?.items}
                    setSelectedTemplateId={setSelectedTemplateId}
                    selectedTemplateId={selectedTemplateId}
                  />
                </TimelineContent>
              </TimelineItem>
            )
          })}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={dotClasses}>Finish</TimelineDot>
            </TimelineSeparator>
          </TimelineItem>
        </Timeline>
      )}
    </div>
  )
}

export default TemplatesPreview
