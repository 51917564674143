const IMPROVER_GOAL_MEMBER_WITH_ACL_PRINCIPALS_FRAGMENT = gql`
  fragment ImproverGoalMemberWithACLPrincipals on ImproverGoalMember {
    ...ImproverGoalMemberFields
    aclPrincipals {
      ...ACLFields
      principal {
        __typename
        ... on ACLPrincipalMembership {
          membership {
            id
            user {
              id
              name
              avatarUrl
            }
          }
        }
        ... on ACLPrincipalMembershipGroup {
          membershipGroup {
            id
            name
          }
        }
      }
    }
  }
`

// CREATE GOAL ITEMS
export const CREATE_GOAL_ITEM = gql`
  mutation CreateImproverGoalMemberObjFormMutation(
    $input: CreateImproverGoalMemberInput!
    $adminEditMode: Boolean!
  ) {
    createImproverGoalMember(input: $input, adminEditMode: $adminEditMode) {
      ...ImproverGoalMemberWithACLPrincipals
      childGoals(adminEditMode: $adminEditMode) {
        ...ImproverGoalMemberWithACLPrincipals
      }
    }
  }
  ${IMPROVER_GOAL_MEMBER_WITH_ACL_PRINCIPALS_FRAGMENT}
`
export const CREATE_PERSONAL_GOAL_ITEM = gql`
  mutation CreatePersonalImproverGoalMemberObjFormMutation(
    $input: CreateImproverGoalMemberInput!
  ) {
    createPersonalImproverGoalMember(input: $input) {
      ...ImproverGoalMemberWithACLPrincipals
      childGoals {
        ...ImproverGoalMemberWithACLPrincipals
      }
    }
  }
  ${IMPROVER_GOAL_MEMBER_WITH_ACL_PRINCIPALS_FRAGMENT}
`

// DELETE GOAL ITEMS
export const DELETE_GOAL_ITEM = gql`
  mutation DeleteImproverGoalMember($id: Int!, $adminEditMode: Boolean!) {
    deleteImproverGoalMember(id: $id, adminEditMode: $adminEditMode) {
      ...ImproverGoalMemberWithACLPrincipals
      childGoals {
        ...ImproverGoalMemberWithACLPrincipals
      }
    }
  }
  ${IMPROVER_GOAL_MEMBER_WITH_ACL_PRINCIPALS_FRAGMENT}
`

// UPDATE GOAL ITEMS
export const UPDATE_GOAL_ITEM = gql`
  mutation UpdateImproverGoalMemberObjFormMutation(
    $input: UpdateImproverGoalMemberInput!
    $id: Int!
    $adminEditMode: Boolean!
  ) {
    updateImproverGoalMember(
      input: $input
      id: $id
      adminEditMode: $adminEditMode
    ) {
      ...ImproverGoalMemberWithACLPrincipals
      childGoals(adminEditMode: $adminEditMode) {
        ...ImproverGoalMemberWithACLPrincipals
      }
    }
  }
  ${IMPROVER_GOAL_MEMBER_WITH_ACL_PRINCIPALS_FRAGMENT}
`
export const FIND_COURSE_BY_ID_QUERY = gql`
  query FindCourseByIdQuery($id: Int!) {
    findCourseById(id: $id) {
      id
      name
      learnerCategory {
        isGlobal
      }
      heroStorageObject {
        downloadUrl
      }
    }
  }
`

export const FIND_LEARNER_COURSES_QUERY = gql`
  query findLearnerCoursesQuery {
    getLearnerCoursesTaskTypeCount {
      id
      name
      learnerCategory {
        id
        name
        isGlobal
      }
      heroStorageObject {
        downloadUrl
      }
    }
  }
`
