import { Dispatch, FC, SetStateAction } from 'react'

import TemplatesPreview from './Preview/TemplatesPreview'
import { GoalTemplate } from './TemplatesCell'
interface TemplatesTrackBuilderProps {
  trackTemplate: GoalTemplate
  selectedTemplateId?: number | null
  setSelectedTemplateId?: Dispatch<SetStateAction<number | null>>
  createGoalTemplate: (parentGoalId?: number) => void
  isLoading?: boolean
}
const TemplatesTrackBuilder: FC<TemplatesTrackBuilderProps> = ({
  trackTemplate,
  selectedTemplateId,
  setSelectedTemplateId,
  createGoalTemplate,
  isLoading,
}) => {
  return (
    <div className="px-6">
      <TemplatesPreview
        trackTemplate={trackTemplate}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        createGoalTemplate={createGoalTemplate}
        isLoading={isLoading}
      />
    </div>
  )
}

export default TemplatesTrackBuilder
