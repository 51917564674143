import { Dispatch, FC, SetStateAction } from 'react'

import { TextField, Tooltip } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

import Switch from 'src/components/Library/Switch'
import { AccessControlListType } from 'src/lib/hooks/ACL/useACL'

import { ProgressionTypes } from '../constants'
import { GoalMemberTemplateFormOptions } from '../interfaces'
interface GoalModalTemplatesEditProps {
  hideObjective?: boolean
  templateFormFields: GoalMemberTemplateFormOptions
  setTemplateStartDate?: Dispatch<SetStateAction<Dayjs>>
  AccessControlList: AccessControlListType
  adminEditMode: boolean
  copyAssigneesToMilestones?: boolean
  setCopyAssigneesToMilestones?: Dispatch<SetStateAction<boolean>>
  goalTitle: string
  setGoalTitle: Dispatch<SetStateAction<string>>
}

export const GoalModalTemplatesEdit: FC<GoalModalTemplatesEditProps> = ({
  hideObjective = false,
  templateFormFields,
  setTemplateStartDate,
  AccessControlList,
  adminEditMode,
  copyAssigneesToMilestones,
  setCopyAssigneesToMilestones,
  goalTitle,
  setGoalTitle,
}) => {
  // Handle the fields changing
  const handleStartDateChange = (newValue: Dayjs) => {
    setTemplateStartDate(newValue)
  }

  const templateHasMilestonesAndIsMilestoneType =
    templateFormFields?.templateType === ProgressionTypes.milestone &&
    templateFormFields?.childGoals.length > 0

  return (
    <div className={`p-6 pt-0 ${hideObjective && '!pb-0'} `}>
      <div className="flex gap-3 items-end">
        <div className="grow">
          <div className="w-full pb-0">
            <p className="text-indigo-600 uppercase font-normal text-sm">
              Goal
            </p>
          </div>
          <TextField
            value={goalTitle}
            onChange={(e) => setGoalTitle(e.target.value)}
            size="small"
            fullWidth
          />
        </div>
        <div className="flex flex-col">
          <span className="text-xs pb-1 mt-4 text-gray-400 uppercase">
            Start Date
          </span>
          <DatePicker
            slots={{
              textField: TextField,
            }}
            slotProps={{
              textField: {
                size: 'small',
                id: 'dueDate',
                name: 'dueDate',
              },
            }}
            value={dayjs(templateFormFields?.startDate)}
            onChange={(newValue) => handleStartDateChange(newValue)}
            className="w-[200px]"
          />
        </div>
      </div>
      <div className="pt-4">
        {!adminEditMode && (
          <p className="text-xs text-gray-500 pl-2 pt-3 pb-2">
            The created goal will only be visible in &quot;Admin Mode&quot;
            unless you assign yourself or a group you are a member of.
          </p>
        )}
        <AccessControlList
          membershipCardTitle="Assign Goal"
          membershipCardText="By assigning to Groups and/or Members, you are giving them access to view this Goal."
          groupsLabelText="Visible to Groups"
          membersLabelText="Assign to Members"
        />
      </div>
      {templateHasMilestonesAndIsMilestoneType && (
        <div className="pt-3 border-t border-gray-200 mt-6">
          <div className="flex flex-row justify-between items-center w-full">
            <div className="text-indigo-600 uppercase font-normal text-sm">
              Milestones
            </div>
            <div>
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  title="This option may be useful when you need to assign members to many milestones"
                >
                  <div>
                    <span className="text-xs text-gray-500 pt-3">
                      Copy goal assignees and groups to milestones
                    </span>
                    <Switch
                      checked={copyAssigneesToMilestones}
                      name="copy-goal-assignees-to-milestones"
                      onChange={(_event, checked) => {
                        setCopyAssigneesToMilestones(checked)
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
