import { FC, useEffect, useState } from 'react'

import dayjs from 'dayjs'
import {
  CreateImproverGoalMemberTemplateMutation,
  CreateImproverGoalMemberTemplateMutationVariables,
  CreateManyImproverGoalMemberTemplateMutation,
  CreateManyImproverGoalMemberTemplateMutationVariables,
  LightenedImproverGoalMemberTemplatesQuery,
  LightenedImproverGoalMemberTemplatesQueryVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Modal from 'src/components/Modal'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useAuth } from 'src/Providers'

import { ProgressionTypes } from '../constants'
import {
  createChildGoalsInput,
  createTemplateMilestones,
  getParentTemplates,
} from '../helpers'
import { GoalMember, GoalMemberTemplateFormOptions } from '../interfaces'
import TemplatesPreview from '../Templates/Preview/TemplatesPreview'
import {
  CREATE_GOAL_TEMPLATE_ITEM,
  CREATE_GOAL_TEMPLATE_ITEMS,
} from '../Templates/Queries/queries'

import { GoalModalFilterBar } from './GoalModalFilterBar'
import { GoalModalFooter } from './GoalModalFooter'
import { GoalModalHeader } from './GoalModalHeader'
import { GoalModalTemplatesEdit } from './GoalModalTemplatesEdit'
import { GoalModalTemplatesSelection } from './GoalModalTemplatesSelection'
const LIGHTWEIGHT_QUERY = gql`
  query LightenedImproverGoalMemberTemplatesQuery(
    $isTemplate: Boolean!
    $isGlobal: Boolean!
  ) {
    goalTemplates: improverGoalMemberTemplatesWithOrderedMilestones(
      isTemplate: $isTemplate
      isGlobal: $isGlobal
    ) {
      ...LightImproverGoalMemberTemplateFragment
      orderedMilestones {
        dayNumber
        items {
          ...LightImproverGoalMemberTemplateFragment
          brokenTemplateLoop
        }
      }
      childGoals {
        ...LightImproverGoalMemberTemplateFragment
      }
      client {
        name
      }
    }
  }

  fragment LightImproverGoalMemberTemplateFragment on ImproverGoalMember {
    id
    clientId
    goalTitle
    goalBody
    isComplete
    createdAt
    dueDate
    startDate
    startValue
    currentValue
    targetValue
    status
    parentGoalId
    isTemplate
    isGlobal
    templateType
    startDateNumber
    startDateFormat
    startDateTrigger
    startDateTriggerMilestoneId
    dueDateNumber
    dueDateFormat
    dueDateTrigger
    dueDateTriggerMilestoneId
    attachedLearnerItemId
  }
`

interface GoalModalProps {
  isModalOpen?: boolean
  closeModal: (createdGoalId?: number) => void
  adminEditMode: boolean
  refetch: () => Promise<unknown>
}
export const GoalModal: FC<GoalModalProps> = ({
  isModalOpen = false,
  closeModal,
  adminEditMode,
  refetch,
}) => {
  const { currentUser } = useAuth()

  const [modalIsSaving, setModalIsSaving] = useState<boolean>(false)
  const [modalStep, setModalStep] = useState<'select' | 'edit'>('select')

  // Header Filter options
  const [showMilestones, setShowMilestones] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  // All Templates
  const [goalTemplates, setGoalTemplates] = useState<GoalMember[]>([])
  const [goalTemplatesById, setGoalTemplatesById] = useState({})

  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null,
  )

  const [templateStartDate, setTemplateStartDate] = useState<dayjs.Dayjs>(null)

  const [templateFormFields, setTemplateFormFields] =
    useState<GoalMemberTemplateFormOptions>({})

  const [copyAssigneesToMilestones, setCopyAssigneesToMilestones] =
    useState<boolean>(true)

  const [goalTitle, setGoalTitle] = useState<string>()

  const { AccessControlList, saveACL, resetACLComponent } = useACL({
    contentPrivateDefault: true,
    resourceType: 'ImproverGoalMember',
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const handleNextAction = () => {
    if (modalStep === 'edit') {
      createGoalTemplate(templateFormFields)
    } else if (modalStep === 'select') {
      setModalStep('edit')
    }
  }

  const [createGoalMilestonesFromTemplateItemMutation] = useMutation<
    CreateManyImproverGoalMemberTemplateMutation,
    CreateManyImproverGoalMemberTemplateMutationVariables
  >(CREATE_GOAL_TEMPLATE_ITEMS, {
    onError: (error) => {
      setModalIsSaving(false)

      toast.error('Problem creating milestones. ' + error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [createGoalFromTemplateItemMutation] = useMutation<
    CreateImproverGoalMemberTemplateMutation,
    CreateImproverGoalMemberTemplateMutationVariables
  >(CREATE_GOAL_TEMPLATE_ITEM, {
    onCompleted: async (newGoal) => {
      await saveACL({ resourceId: newGoal.createImproverGoalMember.id })
      // Reset the ACL form fields after creating the goal and ACL rules
      resetACLComponent()

      if (
        templateFormFields?.templateType === ProgressionTypes.milestone &&
        templateFormFields?.childGoals?.length > 0
      ) {
        const childGoalsInput = createChildGoalsInput(
          templateFormFields,
          currentUser,
          newGoal.createImproverGoalMember?.id,
        )
        // Create the goal milestones
        await createGoalMilestonesFromTemplateItemMutation({
          variables: {
            input: childGoalsInput,
            initialPermissions: copyAssigneesToMilestones
              ? 'SAME_AS_PARENT'
              : 'ALLOW_ALL',
          },
        })
      }

      await refetch()
      toast.success('Goal Created', {
        duration: 2000,
        className: 'flex-column',
      })
      closeModal(newGoal.createImproverGoalMember.id)
      setModalIsSaving(false)
    },
    onError: (error) => {
      setModalIsSaving(false)

      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const createGoalTemplate = async (
    createTemplateOptions: GoalMemberTemplateFormOptions,
  ) => {
    // Now Loading
    setModalIsSaving(true)

    const result = await createGoalFromTemplateItemMutation({
      variables: {
        adminEditMode: true,
        input: {
          clientId: currentUser.parentData.id,
          parentGoalId: createTemplateOptions?.parentGoalId ?? null,
          goalTitle: goalTitle ?? '',
          goalBody: createTemplateOptions?.goalBody ?? '',
          startDate: (createTemplateOptions?.startDate ?? dayjs()).format(),
          dueDate: (
            createTemplateOptions?.dueDate ?? dayjs().add(14, 'd')
          ).format(),
          startValue: createTemplateOptions?.startValue ?? 0,
          targetValue: createTemplateOptions?.targetValue ?? 0,
          templateId: createTemplateOptions?.id ?? null,
          templateType: createTemplateOptions?.templateType ?? null,
          isComplete: false,
          status: 'In Progress',
          // current value must equal start value, so the goal/milestone can be 0% complete on create.
          currentValue: createTemplateOptions?.startValue ?? 0,
        },
      },
    })

    return result
  }

  // Get the templates QUERY
  useQuery<
    LightenedImproverGoalMemberTemplatesQuery,
    LightenedImproverGoalMemberTemplatesQueryVariables
  >(LIGHTWEIGHT_QUERY, {
    variables: {
      isTemplate: true,
      isGlobal: true,
    },
    onCompleted: (data) => {
      const { goalTemplates } = data
      const parentTemplates = getParentTemplates(goalTemplates)
      // @ts-expect-error: FIX ME
      setGoalTemplates(parentTemplates)
    },
  })

  // Reset the form fields
  const resetFormFields = () => {
    setSelectedTemplateId(null)
    setModalStep('select')
    setTemplateFormFields({})
    setTemplateStartDate(null)
    setGoalTitle('')
  }

  // Toggle Modal Open
  useEffect(() => {
    !isModalOpen && resetFormFields()
  }, [isModalOpen])

  // Set the form fields
  useEffect(() => {
    const templatesById = {}

    for (const template of goalTemplates) {
      templatesById[template?.id] = template
    }

    setGoalTemplatesById(templatesById)
  }, [goalTemplates])

  // Selected Template Changed
  useEffect(() => {
    // If a new template is selected, update the object
    if (selectedTemplateId) {
      // Set the template
      const selectedTemplate: GoalMember = goalTemplatesById[selectedTemplateId]

      // Set the start and end dates
      const now = templateStartDate ?? dayjs()
      const startDate = now
      const endDate = now.add(
        selectedTemplate?.dueDateNumber,
        selectedTemplate?.dueDateFormat as dayjs.ManipulateType,
      )

      const startValue =
        selectedTemplate?.templateType === ProgressionTypes.custom
          ? selectedTemplate?.startValue
          : 0

      const targetValue =
        selectedTemplate?.templateType === ProgressionTypes.custom
          ? selectedTemplate?.targetValue
          : 0

      // Do the same for the milestones
      const templateMilestones = createTemplateMilestones(selectedTemplate, now)

      const templateFields = {
        id: selectedTemplate?.id,
        parentGoalId: selectedTemplate?.parentGoalId,
        goalBody: selectedTemplate?.goalBody,
        startDate: startDate,
        dueDate: endDate,
        startValue: startValue,
        targetValue: targetValue,
        childGoals: templateMilestones,
        templateId: selectedTemplate?.id,
        templateType: selectedTemplate?.templateType,
        attachedLearnerItemId: null,
      }

      setGoalTitle(selectedTemplate?.goalTitle)
      setTemplateFormFields(templateFields)
    }
  }, [selectedTemplateId, templateStartDate])

  return (
    <Modal
      open={isModalOpen}
      closeButtonVisible={false}
      className="max-w-2xl !p-0"
      dialogClassName="!bg-white !p-0"
    >
      <div id="createTemplateGoalModal">
        <GoalModalHeader
          selectedTemplate={goalTemplatesById[selectedTemplateId]}
          closeModal={closeModal}
          modalIsSaving={modalIsSaving}
          modalStep={modalStep}
        />
        <div className="flex flex-col border-t border-gray-200 py-4">
          {modalStep === 'select' && (
            <>
              <GoalModalFilterBar
                showMilestones={showMilestones}
                setShowMilestones={setShowMilestones}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <GoalModalTemplatesSelection
                showMilestones={showMilestones}
                searchValue={searchValue}
                templateOptions={goalTemplates}
                selectedTemplateId={selectedTemplateId}
                setSelectedTemplateId={setSelectedTemplateId}
              />
            </>
          )}
          {modalStep === 'edit' && (
            <>
              <GoalModalTemplatesEdit
                {...{
                  hideObjective: true,
                  templateFormFields,
                  setTemplateStartDate,
                  AccessControlList,
                  adminEditMode,
                  copyAssigneesToMilestones,
                  setCopyAssigneesToMilestones,
                  goalTitle,
                  setGoalTitle,
                }}
              />
              <TemplatesPreview
                trackTemplate={goalTemplatesById[selectedTemplateId]}
                hideObjective={true}
              />
            </>
          )}
        </div>
        <GoalModalFooter
          selectedTemplate={goalTemplatesById[selectedTemplateId]}
          closeModal={closeModal}
          modalIsSaving={modalIsSaving}
          modalStep={modalStep}
          setModalStep={setModalStep}
          handleNextAction={handleNextAction}
        />
      </div>
    </Modal>
  )
}
