import { Dispatch, FC, SetStateAction } from 'react'

import Empty from 'src/components/Library/Empty/Empty'
import { AccessControlListType } from 'src/lib/hooks/ACL/useACL'
import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'

import { FormOptions } from '../../interfaces'
import TemplatesPreview from '../Preview/TemplatesPreview'
import TemplateEditForm from '../TemplateEditForm'
import { GoalTemplate } from '../TemplatesCell'

import TemplatesSidebarHeader from './TemplatesSidebarHeader'
interface TemplatesSidebarProps {
  setSelectedTemplateId?: Dispatch<SetStateAction<number | null>>
  selectedTemplate: GoalTemplate | null
  trackTemplate: GoalTemplate | null
  createGoalTemplate: (parentGoalId?: number) => Promise<unknown>
  updateGoalTemplate: (args: FormOptions) => Promise<unknown>
  deleteGoalTemplate: (id: number) => Promise<unknown>
  isSaving: boolean
  isLoading: boolean
  editMode: boolean
  goToEditTemplate: (id: number) => void
  courseOptions: LearnerCourseWithStorageObject[]
  AccessControlList: AccessControlListType
  formFieldsChanged: boolean
  setFormFieldsChanged: Dispatch<SetStateAction<boolean>>
  handleFormSave: boolean
  setHandleFormSave: Dispatch<SetStateAction<boolean>>
  resetToTemplateList: () => void
}
const TemplatesSidebar: FC<TemplatesSidebarProps> = ({
  setSelectedTemplateId,
  selectedTemplate,
  trackTemplate,
  createGoalTemplate,
  deleteGoalTemplate,
  updateGoalTemplate,
  isSaving,
  isLoading,
  editMode,
  goToEditTemplate,
  courseOptions,
  AccessControlList,
  formFieldsChanged,
  setFormFieldsChanged,
  handleFormSave,
  setHandleFormSave,
  resetToTemplateList,
}) => {
  return (
    <div className="h-full w-full max-w-[450px] overflow-y-scroll border-l border-gray-300 bg-white">
      {!selectedTemplate?.id && (
        <div className="grid h-full place-items-center">
          <div className="flex flex-col items-center justify-center gap-4">
            <Empty title="Nothing Selected" />
          </div>
        </div>
      )}

      {selectedTemplate?.id &&
        (editMode ? (
          <div className="relative block">
            <TemplatesSidebarHeader
              onClickDelete={deleteGoalTemplate}
              onClickEdit={goToEditTemplate}
              editMode={editMode}
              selectedTemplate={selectedTemplate}
              isSaving={isSaving}
              formFieldsChanged={formFieldsChanged}
              setHandleFormSave={setHandleFormSave}
              resetToTemplateList={resetToTemplateList}
            />
            <TemplateEditForm
              isLoading={isLoading}
              trackTemplate={trackTemplate}
              selectedTemplate={selectedTemplate}
              createGoalTemplate={createGoalTemplate}
              updateGoalTemplate={updateGoalTemplate}
              setSelectedTemplateId={setSelectedTemplateId}
              courseOptions={courseOptions}
              AccessControlList={AccessControlList}
              isSaving={isSaving}
              formFieldsChanged={formFieldsChanged}
              setFormFieldsChanged={setFormFieldsChanged}
              handleFormSave={handleFormSave}
              setHandleFormSave={setHandleFormSave}
            />
          </div>
        ) : (
          <div>
            <TemplatesSidebarHeader
              onClickDelete={deleteGoalTemplate}
              onClickEdit={goToEditTemplate}
              editMode={editMode}
              selectedTemplate={selectedTemplate}
              isSaving={isSaving}
              formFieldsChanged={formFieldsChanged}
              setHandleFormSave={setHandleFormSave}
              resetToTemplateList={resetToTemplateList}
            />
            <TemplatesPreview trackTemplate={trackTemplate} />
          </div>
        ))}
    </div>
  )
}

export default TemplatesSidebar
