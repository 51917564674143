import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { isNil } from 'ramda'

import { Link, routes } from '@redwoodjs/router'

import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel/CircularProgressWithLabel'
import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'

import { CoursePreviewImage } from './CoursePreviewImage'

const LearnerCourseProgressPreview: FC<{
  learnerCourse: LearnerCourseWithStorageObject
  percentProgress: number
}> = ({ learnerCourse, percentProgress }) => {
  // if course attached, but not found (no permission or deleted)
  if (!learnerCourse) {
    return (
      <div className="flex flex-col items-center rounded-lg overflow-hidden bg-white">
        <div className="w-full h-24 bg-gray-100 rounded p-5 grid place-items-center">
          <span className="block text-gray-400 text-sm font-light text-center">
            You do not have access to the linked course.
            <br />
            Please select another.
          </span>
        </div>
      </div>
    )
  }

  // Set the Course Preview Image
  const learnerCoursePreviewImageUrl =
    learnerCourse?.heroStorageObject?.downloadUrl

  // Set the course title
  const courseTitle = learnerCourse?.name

  const isMilestoneCreated = !isNil(percentProgress)

  return (
    <div className="flex flex-col rounded-lg overflow-hidden shadow-md bg-white border-t border-gray-200 relative">
      {isMilestoneCreated && (
        <Link
          className="flex absolute left-2 mt-2 text-indigo-600 bg-white p-1 rounded shadow-md border border-gray-100"
          to={routes.learnerCourse({ id: learnerCourse.id })}
          title="Go to Course"
        >
          Open course
          <ArrowTopRightOnSquareIcon className="w-6 h-6 ml-1" />
        </Link>
      )}
      <CoursePreviewImage
        imageUrl={learnerCoursePreviewImageUrl}
        variant="large"
      />
      <div className="flex w-full flex-row gap-2 p-4 items-center justify-between border-t">
        <p className="font-bold">{courseTitle}</p>
        <CircularProgressWithLabel
          value={percentProgress}
          variant="determinate"
          size="50px"
          sx={{
            color: percentProgress >= 100 ? '#34d399' : '#2563eb',
          }}
        />
      </div>
    </div>
  )
}

export default LearnerCourseProgressPreview
